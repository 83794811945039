import {toast} from "react-toastify";
import checkIsAuth from "./checkIsAuth";
import axios from "axios";
import {auth} from "../config/firebase";

export const copyToClipboard = (data) => {
    try {
        navigator.clipboard.writeText(data);
    } catch (error) {
        toast.error("Error copying URL")
    }
}

export const shareImage = (image) => {
    try {
        copyToClipboard(`${process.env.REACT_APP_CLIENT_URL}images/${image?.uid}`)
        toast.success("Image URL copied successfully.")
    } catch (error) {
        console.log(error.message)
    }
}

const base64ToBlob = (base64, mimeType = '') => {
    // Ensure we're working with the Base64-encoded part only, excluding any data URL scheme if present.
    const base64Data = base64.split(';base64,').pop();

    // Decode Base64 string to binary data
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = slice.split('').map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    // Create a blob from the byte arrays
    return new Blob(byteArrays, { type: mimeType });
};


export const handleImageDownload = async (imageObject) => {
    toast.info('Downloading image...')
    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + 'getBase64Image', {
            imageUid: imageObject.uid,
            userUid: auth.currentUser.uid
        })
        if (response) {
            const base64 = response.data.imageData;
            const blob = base64ToBlob(base64, 'image/jpg');
            const objectUrl = URL.createObjectURL(blob);

            // Create a downloadable link
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = `${new Date().toLocaleString()}.jpg`; // You can set the desired file name
            link.click();
        } else {
            console.error('Image fetch failed');
            toast.error("Downloading image failed...");
        }
    } catch (error) {
        toast.error(error.message);
        console.error('Error fetching image:', error);
    }
};
