import React, {useContext, useEffect, useState} from "react";
import {
  Chip,
  Collapse,
  Drawer,
  Fade,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText, Popover,
  Stack,
  Toolbar, useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  IconAdjustments, IconLayoutSidebarLeftCollapse,
  IconRefresh,
  IconUser
} from "@tabler/icons-react";
import Box from "@mui/material/Box";
import {deepPurple} from "@mui/material/colors";
import {ExpandLess, ExpandMore, Square} from "@mui/icons-material";
import {HexColorPicker} from "react-colorful";
import {theme} from "../../../utils/Theme";
import {Context as AppContext} from "../../../context/AppContext";

const filterItems = [
  {
    icon: <IconRefresh/>,
    name: "Orientation",
    type: "choice",
    items: ["Portrait", "Square", "Landscape"],
    func: (option, image) => {
      return option?.toLowerCase() === image?.orientation?.toLowerCase()
    },
  },
  {
    icon: <IconUser/>,
    name: "People",
    type: "choice",
    items: ["With people", "No people",],
    func: (option, image) => {
      return option === "No people" ? !image["number_of_people"] || image["number_of_people"] === 0 : true
    },
    submenu: [
      {
        name: "Number of people",
        type: "choice",
        items: ["1", "2", "3", "4+",],
        func: (option, image) => {
          if (!image["number_of_people"]) {
            return false;
          }
          if (option === "4+") {
            return +image["number_of_people"] >= 4;
          } else {
            return +image["number_of_people"] >= +option;
          }
        },
      },
      {
        name: "Gender",
        type: "choice",
        items: ["Male", "Female",],
        func: (option, image) => {
          const value = option === "Male" ? "Men" : "Women";
          return image.gender && image?.gender?.includes(value.toLowerCase())
        }
      },
      {
        name: "Age",
        type: "choice",
        items: ["Infant", "Child", "Teen", "Young adult", "Adults", "Elder"],
        func: (option, image) => {
          return image?.age && image?.age?.includes(option.toLowerCase())
        }
      }
    ]
  },
]

export const Filter = ({open, onClose, filterParams, setFilterParams}) => {
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const {state: {images}} = useContext(AppContext)
  const [items, setItems] = useState([]);

  useEffect(() => {
    // here I need to set filter items to be unique
    if (images?.length) {
      const newItems = [...filterItems];
      const orientationItem = newItems?.find(item => item?.name === "Orientation");
      if (orientationItem) {
        orientationItem.items = Array.from(new Set(images?.map(item => item?.orientation).flat()));
      }
      const peopleItem = newItems?.find(item => item?.name === "People");
      if (peopleItem) {
        const genderItem = peopleItem?.submenu?.find(item => item?.name === "Gender");
        if (genderItem) {
          genderItem.items = Array.from(new Set(images?.map(item => item?.gender).flat()));
        }
        const ageItem = peopleItem?.submenu?.find(item => item?.name === "Age");
        if (ageItem) {
          ageItem.items = Array.from(new Set(images?.map(item => item?.age).flat()));
        }
      }


      setItems(newItems);
    }
  }, [images])


  const filterBox = <List sx={{mt: 0, py: 0}}>
    <ListItemButton sx={{color: "secondary", borderBottom: 1, borderColor: "divider"}} onClick={onClose}>
      <ListItemIcon>
        <IconAdjustments color={deepPurple[500]}/>
      </ListItemIcon>
      <ListItemText
        primary={<Typography sx={{color: deepPurple[500], fontSize: 18, fontWeight: "800"}}>Filters</Typography>}/>
      <IconLayoutSidebarLeftCollapse color={deepPurple[500]}/>
    </ListItemButton>
    {
      items?.map(item => <FilterItem key={item?.name} item={item} filterParams={filterParams}
                                           onChangeFilter={(params) => setFilterParams(params)}/>)
    }
  </List>

  return (
    open &&
    <>
      <Fade in timeout={300}>
        <div>
          {
            md ?
              <Drawer
                anchor={"left"}
                open={open}
                onClose={onClose}
                sx={{
                  display: {sm: "inherit", md: "none"},
                  height: "100vh",
                  flex: 1,
                  maxWidth: "360px",
                  width: "100% !important",
                }}
                PaperProps={{
                  sx: {
                    maxWidth: "360px",
                    width: "100% !important",
                  },
                }}
              >
                {filterBox}
              </Drawer>
              :
              <Box
                sx={{
                  minWidth: "320px",
                  maxWidth: "360px",
                  width: "33vw",
                  height: '100%',
                  overflowY: "auto",
                  borderRight: 1,
                  borderColor: "divider",
                  position: "relative",
                  display: {sm: "none", md: "inherit"}
                }}
              >
                {filterBox}
              </Box>
          }
        </div>
      </Fade>
    </>
  )
}

const FilterItem = ({item, filterParams, onChangeFilter}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        {item?.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
        <ListItemText
          primary={<Typography sx={{fontSize: 14, fontWeight: open ? 800 : 500}}>{item?.name}</Typography>}/>
        {open ? <ExpandLess/> : <ExpandMore/>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{px: 2}}>
          {
            {
              "choice": <ChoiceInput item={item} filterParams={filterParams} onChange={onChangeFilter}/>,
              "color": <ColorPickerInput filterParams={filterParams} onChange={onChangeFilter}/>
            }[item?.type]
          }
        </Box>
        {
          item?.submenu && item?.submenu?.length > 0 &&
          item?.submenu?.map(menuItem => {
            return <FilterItem key={menuItem?.name} item={menuItem} filterParams={filterParams}
                               onChangeFilter={onChangeFilter}/>
          })
        }
      </Collapse>
    </>
  )
}

const ChoiceInput = ({item, filterParams, onChange}) => {
  const {items, func} = item;
  const onSelectChoice = (newItem) => {
    let params = [...filterParams];
    if (params.find(param => param.value === newItem)) {
      params = params.filter(param => param.value !== newItem);
    } else {
      params = params.filter(param => !items.includes(param?.value));
      params.push({type: "choice", func: func, value: newItem});
    }
    onChange(params);
  }

  const selectedParams = filterParams?.map(param => param?.value);

  return (
    <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"} sx={{py: 1}}>
      {
        items?.map(item => <Chip size={"small"} key={item} label={item}
                                 sx={{textTransform: "capitalize"}}
                                 variant={selectedParams?.includes(item) ? "contained" : "outlined"} color={"secondary"}
                                 onClick={() => onSelectChoice(item)}/>)
      }
    </Stack>
  )
}
const ColorPickerInput = ({filterParams, onChange}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectColor = (colorPicker) => {
    let params = [...filterParams];
    let colorItem = params.find(param => param.type === "color");
    if (colorItem) {
      colorItem.value = colorPicker;
    } else {
      colorItem = {type: "color", value: colorPicker};
      params.push(colorItem)
    }

    onChange(params);
  }

  const color = filterParams?.find(param => param?.type === "color")?.value || null;

  return (
    <>
      <Chip
        variant={"outlined"}
        label={color || "Choose color"}
        onClick={handleClick}
        icon={color && <Square style={{color: color}}/>}
      />
      <Popover
        id="demo-color-menu"
        aria-labelledby="demo-color-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          mt: 1,
          overflow: "hidden"
        }}
        PaperProps={{
          sx: {
            bgcolor: "transparent",
            boxShadow: 0,
            overflow: "hidden"
          }
        }}
      >
        <HexColorPicker color={color || ""} onChange={onSelectColor}/>
      </Popover>
    </>
  )
}