import {collection} from "firebase/firestore";
import {db} from "../config/firebase";

export const USER_COLLECTION = "users";
export const USER_SUBSCRIPTION_COLLECTION = "user_subscription";
export const USER_COLLECTIONS_COLLECTION = "user_collections";
export const CART_COLLECTION = "user_cart";
export const USER_IMAGES_COLLECTION = "user_images";

export const usersCollection = collection(db, USER_COLLECTION);
export const userCollectionsCollection = collection(db, USER_COLLECTIONS_COLLECTION);
export const cartCollection = collection(db, CART_COLLECTION);
export const userImagesCollection = collection(db, USER_IMAGES_COLLECTION);
