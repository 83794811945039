import React, {useState} from "react";

export const Page = {
  LOGIN : "LOGIN",
  REGISTER : "REGISTER",
  FORGOT_PASSWORD : "FORGOT_PASSWORD",
}

export const SignInContext = React.createContext({
  open: false,
  page: Page.LOGIN, setPage: () => {},
  openModal: () => {
  },
  closeModal: () => {
  },
})


export const SignInContextProvider = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(Page.LOGIN);

  const handleOpen = (page = Page.LOGIN) => {
    setIsOpen(true);
    setPage(page);
  }

  const handleClose = () => {
    setIsOpen(false);
    setPage(Page.LOGIN)
  }

  return (
    <SignInContext.Provider
      value={{
        open: isOpen,
        page: page, setPage: setPage,
        openModal: handleOpen,
        closeModal: handleClose,
      }}
    >
      {props.children}
    </SignInContext.Provider>
  )
}