import React, {memo, useContext, useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Grid, IconButton, Stack, Fade} from "@mui/material";
import {LoginPage} from "../../pages/auth/login/LoginPage";
import {RegisterPage} from "../../pages/auth/register/RegisterPage";
import {grey} from "@mui/material/colors";
import {IconX} from "@tabler/icons-react";
import {Page, SignInContext} from "../../context/SignInContext";
import {ForgotPasswordPage} from "../../pages/auth/forgot-password/ForgotPasswordPage";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 860,
    width: '95%',
    height: "80dvh",
    minHeight: "500px",
    bgcolor: 'background.paper',
    borderRadius: "0.75rem",
    border: '0px solid #000',
    boxShadow: 24,
    p: 0,
};


const SignInModal = () => {
    const {open, closeModal, openModal, page, setPage} = useContext(SignInContext);

    return (
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open} timeout={300}>
                <Box sx={style}>
                    <Grid container sx={{height: "100%"}}>
                        <Grid id={"signin-bg"} item xs={6} sx={{display: {xs: 'none', md: 'block'}, height: '100%', p: "40px" }}>
                            <Stack direction={"column"} justifyContent={"space-between"} sx={{height: '100%'}}>
                                <div style={{flex: 1}}/>
                                <Box>
                                    <Typography sx={{mt: "auto"}} fontWeight={700} fontSize={"1.7rem"} fontStyle={"normal"} lineHeight={"normal"} color={"#FFF"}>
                                        Bring your vision to life.
                                    </Typography>
                                    <Typography color={"#FFF"} sx={{mt: 2, fontWeight: "300", fontSize: "0.85rem"}}>
                                        <b>Access our library with thousands</b> of high-quality assets.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column", maxHeight: "100%", overflowY: 'auto' }}>
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{width: '100%'}}>
                                <div/>
                                <IconButton
                                    size={"small"}
                                    onClick={closeModal}
                                >
                                    <IconX />
                                </IconButton>
                            </Stack>
                            {
                                {
                                    "LOGIN": <LoginPage setPage={setPage} handleClose={closeModal}/>,
                                    "REGISTER": <RegisterPage setPage={setPage} handleClose={closeModal}/>,
                                    "FORGOT_PASSWORD": <ForgotPasswordPage setPage={setPage} handleClose={closeModal}/>
                                }[page]
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default memo(SignInModal)