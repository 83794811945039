import React from "react";
import {IconButton, Tooltip} from "@mui/material";
import {deepPurple, grey} from "@mui/material/colors";

export const SecondaryIconButton = ({ title, selected, onClick, children, size }) => {
    return (
        <Tooltip title={title}>
            <IconButton
                size={size || "medium"}
                onClick={onClick}
                sx={{
                    m: 1,
                    bgcolor: selected ? deepPurple[600] : deepPurple[200],
                    color: selected ? deepPurple[200] : deepPurple[600],
                    "&:hover": {
                        bgcolor: selected ? deepPurple[200] : deepPurple[600],
                        color: selected ? deepPurple[600] : deepPurple[200],
                    }
                }}
            >
                {children}
            </IconButton>
        </Tooltip>
    )
}


export const DarkIconButton = ({ title, selected, onClick, children, size }) => {
    return (
        <Tooltip title={title}>
            <IconButton
                size={size || "medium"}
                onClick={onClick}
                sx={{
                    m: 1,
                    bgcolor: grey[200],
                    color: grey[600],
                    "&:hover": {
                        bgcolor:grey[600],
                        color: grey[200],
                    }
                }}
            >
                {children}
            </IconButton>
        </Tooltip>
    )
}

export const DarkSecondaryIconButton = ({ title, onClick, children, size }) => {
    return (
        <Tooltip title={title}>
            <IconButton
                size={size || "medium"}
                onClick={onClick}
                sx={{
                    m: 1,
                    bgcolor: grey[200],
                    color: grey[600],
                    "&:hover": {
                        bgcolor: deepPurple[200],
                        color: deepPurple[600],
                    }
                }}
            >
                {children}
            </IconButton>
        </Tooltip>
    )
}