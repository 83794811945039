import React, {useState} from "react";
import {Box, Button, Stack, TextField, Typography} from "@mui/material";
import {deepPurple, grey} from "@mui/material/colors";
import {ArrowForwardIos} from "@mui/icons-material";
import {auth} from "../../../config/firebase";
import {sendPasswordResetEmail} from "firebase/auth";
import {Page} from "../../../context/SignInContext";
import {toast} from "react-toastify";

export const ForgotPasswordPage = ({setPage, handleClose}) => {
  const [email, setEmail] = useState("");

  const forgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      handleClose()
      toast.success("Check your email for password reset link")
    } catch (error) {
      console.log(error);
      toast.error("Failed to send password reset link")
    }
  }

  return (
    <Stack direction={"column"} gap={1} sx={{flex: 1}} justifyContent={'center'}
           alignItems={"center"}>
      {/*HEADER*/}
      <Stack direction={"column"} gap={1} sx={{width: '100%'}}>
        {/*TITLE*/}
        <Typography sx={{color: grey[900]}} variant={'h2'} textAlign={"center"}
                    fontWeight={"bold"}>Forgot Password</Typography>
        {/*SIGN UP*/}
        <Box sx={{textAlign: "center"}}>
          <Typography sx={{color: grey[700]}} component={"span"}>Already have account?</Typography>
          <Typography variant={"span"} onClick={() => setPage(Page.LOGIN)} sx={{
            pl: 1,
            color: deepPurple[500],
            fontWeight: 'bold',
            textDecoration: "none",
            cursor: "pointer"
          }}>Sign in</Typography>
        </Box>
      </Stack>
      {/*LOGIN WITH MAIL*/}
      <Stack direction={"column"} gap={1} sx={{width: '100%'}}>
        <TextField
          type={"email"}
          variant={"standard"}
          required
          fullWidth
          label={"Email"}
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder={"Enter your email address"}
        />
        <Button
          fullWidth
          variant={"contained"}
          disabled={!email}
          onClick={forgotPassword}
          sx={{
            textTransform: "capitalize",
            bgcolor: deepPurple[400],
            fontWeight: "bold",
            '&:hover': {bgcolor: deepPurple[600]}
          }}
        >
          Continue <ArrowForwardIos sx={{fontSize: 12}}/>
        </Button>
      </Stack>
    </Stack>
  )
}