import React, {useState} from "react";

export const CartContext = React.createContext({
    openCart: false,
    openCartModal: () => {},
    closeCartModal: () => {},
})

export const CartContextProvider = props => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <CartContext.Provider
            value={{
              openCart: isOpen,
              openCartModal: () => setIsOpen(true),
              closeCartModal: () => setIsOpen(false),
            }}
        >
            {props.children}
        </CartContext.Provider>
    )
}