import React, {useContext, useEffect, useState} from "react";
import {MainLayout} from "../../layouts/main-layout/MainLayout";
import {
  Button,
  Box,
  Stack,
  TextField,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {grey} from "@mui/material/colors";
import {IconChevronRight, IconMail, IconPhone} from "@tabler/icons-react";
import {Context as AppContext} from "../../context/AppContext";
import {auth} from "../../config/firebase";

export const RequestImage = () => {
  const [contactForm, setContactForm] = useState({});

  useEffect(() => {
    if (localStorage.getItem("request-form")){
      let latestRequestForm = JSON.parse(localStorage.getItem("request-form") || "") || {};
      if (latestRequestForm) {
        if (latestRequestForm?.message) {
          delete latestRequestForm.message;
        }
        setContactForm({...latestRequestForm});
      }
    } else {
      setContactForm({
        // name: auth?.currentUser?.displayName,
        email: auth?.currentUser?.email
      })
    }

  }, [auth.currentUser])

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    updateForm(key, value);
  }

  const updateForm = (key, value) => {
    setContactForm(prev => ({...prev, [key]: value}))
  }

  const onSubmit = () => {
    if (contactForm) {
      localStorage.setItem("request-form", JSON.stringify(contactForm));
      // TODO submit request image form
    }
  }

  return (
    <MainLayout>

      <Stack alignItems={"center"} justifyContent={"center"} sx={{position: "relative", minHeight: "calc(100dvh - 80px)"}}>
        <Box className={"mission-bg"}
             sx={{position: "absolute", top: 0, left: 0, zIndex: -1, width: "100%", height: "100%"}}/>
        <Box
          sx={{
            position: "absolute", top: 0, left: 0,
            width: "100%", height: "100%", zIndex: -1,
            background: "linear-gradient(260deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.5) 100%), linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 20%)"
          }}/>
        <Grid container sx={{p: 4}}>
          <Grid xs={12} md={7} sx={{m: "auto", height: '100%'}}>
            <Stack maxWidth={"500px"} direction={"column"} alignItems={"center"} justifyContent={"center"} gap={1} sx={{mx: "auto"}}>
              <Typography fontWeight={900} fontSize={26} align={"center"}>
                Get a personalized package for your creative team’s needs
              </Typography>
              <Typography fontWeight={400} fontSize={15} color={grey[600]} align={"center"}>
                Simplify your creative process with 10,000+ images, and share access with your team hassle-free.
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} md={5}>
            <Stack direction={"column"} maxWidth={"sm"} gap={2}
                   sx={{m: 'auto'}}>

              <Stack gap={2} sx={{flexDirection: {xs: "column", md: "row"}}}>
                <TextField
                  variant={"outlined"}
                  value={contactForm?.name}
                  sx={{flex: 1, bgcolor: "#fff"}} placeholder={"Enter your name"}
                  label={"First Name"}
                  name={"name"} required
                  onChange={onChange}
                />
                <TextField
                  variant={"outlined"}
                  value={contactForm?.lastName}
                  sx={{flex: 1, bgcolor: "#fff"}} placeholder={"Enter your last name"}
                  label={"Last Name"}
                  name={"lastName"} required
                  onChange={onChange}
                />
              </Stack>

              <TextField
                variant={"outlined"}
                value={contactForm?.email}
                sx={{flex: 1, bgcolor: "#fff"}} type={"email"}
                label={"Business Email Address"} placeholder={"johndoe@gmail.com"}
                name={"Email"} required
                InputProps={{
                  endAdornment: <InputAdornment position={"end"}><IconMail size={18}/></InputAdornment>
                }}
                onChange={onChange}
              />

              <TextField
                variant={"outlined"}
                value={contactForm?.phoneNumber}
                sx={{flex: 1, bgcolor: "#fff"}} type={"tel"}
                placeholder={"Enter your phone number"}
                label={"Phone number"} required
                name={"phoneNumber"}
                InputProps={{
                  endAdornment: <InputAdornment position={"end"}><IconPhone size={18}/></InputAdornment>
                }}
                onChange={onChange}
              />
              <Stack gap={2} sx={{flexDirection: {xs: "column", md: "row"}}}>
                <TextField
                  variant={"outlined"}
                  sx={{flex: 1, bgcolor: "#fff"}} placeholder={"Enter company name"}
                  label={"Company name"}
                  name={"companyName"}
                  value={contactForm?.companyName}
                  required
                  onChange={onChange}
                />


                <TextField
                  variant={"outlined"}
                  sx={{flex: 1, bgcolor: "#fff"}} placeholder={"Country"}
                  label={"Country"}
                  name={"country"}
                  value={contactForm?.country}
                  required
                  onChange={onChange}
                />
              </Stack>
              <TextField
                variant={"outlined"}
                sx={{flex: 1, bgcolor: "#fff"}} placeholder={"Enter your message"}
                label={"Message"}
                name={"message"}
                value={contactForm?.message}
                required
                multiline
                minRows={3}
                maxRows={5}
                onChange={onChange}
              />

              <Button
                variant={"contained"} color={"secondary"}
                onClick={onSubmit}
                sx={{borderRadius: 5, textTransform: "none"}} endIcon={<IconChevronRight size={18} />}
              >
                Submit
              </Button>

            </Stack>
          </Grid>
        </Grid>
      </Stack>

    </MainLayout>
  )
}