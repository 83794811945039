import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  TextField
} from "@mui/material";
import {deepPurple, grey} from "@mui/material/colors";
import {SecondaryIconButton} from "../../../components/buttons/CustomIconButton";
import {IconCheck, IconMinus, IconPhoto, IconPlus, IconX} from "@tabler/icons-react";
import {useContext, useEffect, useMemo, useState} from "react";
import {Context as AppContext} from "../../../context/AppContext";
import useDebounce from "../../../hooks/useDebounce";
import {Image} from "../../../components/image/Image";
import {CreateCollectionModal} from "../../collections/CreateCollectionModal";

export default function CollectionMenu({open, anchorEl, handleClose, onClose, image}) {
  const {state: {collections}, createCollection, updateCollection} = useContext(AppContext);
  const [search, setSearch] = useState("");
  const [searchedCollections, setSearchedCollections] = useState([]);
  const searchDebounced = useDebounce(search, 500);

  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    setSearchedCollections(collections?.filter(item => item?.name?.toLowerCase().startsWith(searchDebounced?.toLowerCase())));
  }, [searchDebounced, collections])

  const handleAddImageInCollection = async (collection) => {
    if (!image || !collection) {
      return;
    }
    let images = [...collection.images];
    const index = images?.findIndex(i => i?.uid === image?.uid);
    if (isFinite(index) && index >= 0) {
      images = images?.filter(i => i?.uid !== image?.uid);
    } else {
      images.push(image);
    }
    await updateCollection(collection?.uid, {
      ...collection,
      images: images
    })
  }


  return (
    <Popover
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        mt: 1
      }}
      PaperProps={{
        sx: {
          bgcolor: "transparent",
          boxShadow: 0,
          "&::before": {
            backgroundColor: "#FFF",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            mt: '3px',
            transform: "rotate(45deg)",
            left: "calc(50% - 6px)"
          }
        }
      }}
    >
      <CreateCollectionModal
        open={openCreateModal}
        handleClose={() => {
          setOpenCreateModal(false);
        }}
      />
      <Box sx={{bgcolor: "#FFF", mt: "9px", borderRadius: 5}}>
        <Box sx={{px: 1, py: 2}}>
          <Typography sx={{textAlign: "center", fontWeight: 900, pb: 1}}>Add to Collection</Typography>
          <TextField
            placeholder={"Search collection..."}
            size={"small"}
            fullWidth
            sx={{bgcolor: "#FFF"}}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              sx: {
                borderRadius: "62.5rem",
                ".Mui-focused fieldset": {borderColor: deepPurple[50]}
              }
            }}
          />
        </Box>
        <List sx={{
          maxHeight: "200px",
          overflowY: "auto",
          bgcolor: "#FFF"
        }}>
          {
            searchedCollections?.map(collection => <CollectionItem key={image?.item} collection={collection} image={image}
                                                                   onClick={handleAddImageInCollection}/>)
          }
        </List>
        <Box sx={{borderBottom: 1, borderColor: "divider", borderRadiusTop: "0.75rem"}}>
          <List sx={{py: 0}}>
            <ListItemButton
              onClick={() => setOpenCreateModal(true)}
              sx={{color: deepPurple[400], direction: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}
            >
              <IconPlus size={15} color={deepPurple[400]} sx={{color: deepPurple[400]}}/>
              <Typography sx={{fontWeight: "bold", color: deepPurple[400]}}>
                Create Collection
              </Typography>
            </ListItemButton>
          </List>
        </Box>
      </Box>

    </Popover>
  );
}

const CollectionItem = ({collection, image, onClick}) => {
  const [hovered, setHovered] = useState(false);
  const inCollection = collection?.images?.find(i => i?.uid === image?.uid);

  const src = collection?.images?.length > 0 ? collection?.images[0]?.imageUrl : null;
  const alt = collection?.images?.length > 0 ? collection?.images[0].name : image?.name;
  return (
    <ListItem key={collection?.uid || collection?.name} sx={{"&:hover": {bgcolor: grey[100]}}}>
      <ListItemIcon>
        {src ?
          <Image
            url={src}
            name={alt}
            width={30} height={30}
            loading="lazy"
          />
          : <IconPhoto/>}
      </ListItemIcon>
      <ListItemText>
        {collection?.name}
      </ListItemText>
      <Box onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <SecondaryIconButton
          title={inCollection ? "Remove from collection" : "Add to collection"}
          size={"small"}
          selected={inCollection}
          onClick={() => onClick(collection)}
        >
          {inCollection ? hovered ? <IconMinus size={12}/> : <IconCheck size={12}/> : <IconPlus size={12}/>}
        </SecondaryIconButton>
      </Box>
    </ListItem>
  )
}

export const CreateCollectionForm = ({open, handleSubmit, handleClose}) => {
  const [name, setName] = useState("");

  useEffect(() => {
    open && setName("");
  }, [open])

  return (
    open &&
    <Stack direction={"row"} gap={1}>
      <TextField
        size={"small"}
        variant={"standard"}
        placeholder={"Name your collection..."}
        onChange={(e) => setName(e.target.value)}
      />
      <IconButton
        size={"small"}
        color={"success"}
        disabled={!name}
        onClick={() => handleSubmit(name)}
      >
        <IconCheck size={14}/>
      </IconButton>
      <IconButton
        size={"small"}
        color={"error"}
        onClick={handleClose}
      >
        <IconX size={14}/>
      </IconButton>
    </Stack>
  )
}