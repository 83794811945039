import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {IconChevronRight, IconClockHeart, IconMenu, IconTrash, IconX} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import Button from "@mui/material/Button";
import {Context as AppContext} from "../../context/AppContext";
import checkIsAuth from "../../utils/checkIsAuth";
import {Image} from "../../components/image/Image";
import {CartContext} from "../../context/CartContext";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

export const CartDrawer = () => {
    const {state: {collections, userDetails}, createCollection, updateCollection, updateUserDetails, buyImages} = useContext(AppContext);
    const {openCart, openCartModal, closeCartModal} = useContext(CartContext);
    const navigate = useNavigate();

    const cart = userDetails?.cart || [];

    const addForLater = async (images) => {
        const COLLECTION_NAME = "Save for later";
        const items = [...collections];
        let index = items?.findIndex(col => col.name === COLLECTION_NAME);
        let collection = null;
        if (isFinite(index) && index >= 0) {
            collection = items[index];
            let newImages = [...collection.images, ...images]
            newImages = newImages.filter((value, index, self) =>
                    index === self.findIndex((t) => (t.uid === value.uid))
            )
            if (newImages) {
                collection.images = [...newImages];
                await updateCollection(collection?.uid, collection);
            }
        } else {
            await createCollection(COLLECTION_NAME, "", [...images], []);
        }
        const cartItems = cart?.filter(cartItem => !images.find(image => image?.uid === cartItem?.uid));
        updateUserDetails({...userDetails, cart: cartItems});
    }

    const removeFromCart = (images) => {
        try {
            const isAuth = checkIsAuth();
            if (isAuth) {
                const items = cart?.filter(cartItem => !images.find(image => image?.uid === cartItem?.uid));
                updateUserDetails({...userDetails, cart: items});
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const onCheckOut = async () => {
        if (!userDetails?.subscription?.numberOfCredits || userDetails?.subscription?.numberOfCredits < cart.length) {
            toast.info("You don't have enough credits to buy images.")
            navigate("/pricing")
            closeCartModal();
            return;
        }
        try {
            await buyImages(cart, userDetails);
            navigate("/downloads");
            closeCartModal();
        } catch (error) {
            toast.error(error?.message || "Failed to purchase images please try again!")
        }
    }


    return (
        <Drawer
            anchor={"right"}
            open={openCart}
            onClose={() => closeCartModal()}
            sx={{
                height: "100vh",
                width: '100%',
                flex: 1,
            }}
            PaperProps={{
                sx: {
                    maxWidth: "360px",
                    width: "100% !important",
                },
            }}
        >
            <Stack direction={"column"} gap={1} sx={{
                height: "100vh",
                maxWidth: "360px",
                width: "100%",
                bgcolor: grey[50]
            }}>
                <Box sx={{ p: 2, bgcolor: grey[100] }}>
                    <Stack direction={"row"} gap={1} sx={{width: "100%"}} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant={"h4"} fontWeight={"bold"}>Cart Items ({cart?.length})</Typography>
                        <IconButton onClick={() => closeCartModal()}>
                            <IconX />
                        </IconButton>
                    </Stack>
                    <Button
                      fullWidth
                      variant={"outlined"}
                      color={"secondary"}
                      disabled={cart?.length === 0}
                      onClick={() => addForLater(cart)}
                      sx={{
                          borderRadius: "62.5rem",
                          p: 1, mt: 1,
                          textTransform: "capitalize"
                      }}
                      startIcon={<IconClockHeart size={18} />}
                    >
                        Save for later
                    </Button>
                </Box>
                <Box sx={{ flex: 1, maxHeight: "100%", overflowY: 'auto' }}>
                    <List>
                        {cart?.map(item => <CartItem key={item.uid} item={item} addForLater={addForLater} removeFromCart={removeFromCart} />)}
                    </List>
                </Box>
                <Box sx={{ p: 2, bgcolor: grey[100] }}>
                    <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"} sx={{width: "100%"}}>
                        <Typography fontWeight={"bold"}>Total Photos:</Typography>
                        <Typography fontWeight={"bold"}>{cart?.length}</Typography>
                    </Stack>
                    <Divider sx={{ py: 1 }} />

                    <Button
                        fullWidth
                        variant={"contained"}
                        color={"secondary"}
                        disabled={cart?.length === 0}
                        onClick={() => onCheckOut(cart)}
                        sx={{
                            borderRadius: "62.5rem",
                            p: 1,
                            mt: 1,
                            textTransform: "capitalize"
                        }}
                        endIcon={<IconChevronRight size={18} />}
                    >
                        Purchase Now
                    </Button>
                </Box>
            </Stack>
        </Drawer>
    )
}

const CartItem = ({item, addForLater, removeFromCart}) => {
    const [hovered, setHovered] = useState(false);

    return (
        <ListItem onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <ListItemIcon>
                <Image
                    url={item.imageUrl}
                    name={item.name}
                    style={{borderRadius: "0.375rem", objectFit: "cover"}}
                    loading="lazy"
                    width={50}
                    height={50}
                />
            </ListItemIcon>
            <ListItemText
                primary={"Standard"}
                sx={{flexDirection: "flex-start", mb: "auto", mt: 0, py: 0}}
                primaryTypographyProps={{
                    sx: {m: 0, mb: "auto", py: 0, color: grey[600], fontWeight: 600}
                }}
            />
            <Stack direction={"row"} gap={1}>
                <IconButton
                  size={"small"} onClick={() => addForLater([item])} color={"secondary"}
                  sx={{ opacity: hovered ? 1 : 0, textTransform: "capitalize", fontSize: "0.75rem" }}
                >
                    <IconClockHeart size={18} />
                </IconButton>
                <IconButton
                  size={"small"} onClick={() => removeFromCart([item])} color={"error"}
                  sx={{ opacity: hovered ? 1 : 0, textTransform: "capitalize", fontSize: "0.75rem" }}
                >
                    <IconTrash size={18} />
                </IconButton>
            </Stack>
        </ListItem>
    )
}

