import React, {useContext, useState} from "react";
import {deepPurple, grey} from "@mui/material/colors";
import {IconButton, Stack, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {IconX} from "@tabler/icons-react";
import Box from "@mui/material/Box";
import {auth} from "../../config/firebase";
import {Page, SignInContext} from "../../context/SignInContext";

export const FreeImageSelectionBanner = () => {
    const [isOpen, setIsOpen] = useState(true);
    const {openModal} = useContext(SignInContext);

    return (
        !auth.currentUser && isOpen &&
        <Box sx={{bgcolor: deepPurple[400], py: 1, minHeight: '0'}}>
            <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignContent={"center"} useFlexGap
                   flexWrap={"wrap"} sx={{width: "100%"}}>
                <div/>
                <Stack direction={"row"} gap={2} justifyContent={"center"} alignItems={"center"} sx={{color: "#FFF"}}>
                    <Typography sx={{fontSize: {sx: 13, md: 14}}}>
                        Get 1 free image every 10 selections.
                    </Typography>
                    <Button
                        variant={"outlined"}
                        size={"small"}
                        onClick={() => openModal(Page.REGISTER)}
                        sx={{
                            display: {xs: "none", md: "inherit"},
                            textTransform: "capitalize",
                            borderRadius: 5,
                            color: "#FFF",
                            borderColor: "#FFF",
                            fontSize: {sx: 13, md: 14},
                            "&:hover": {
                                borderColor: "#FFF",
                                bgcolor: "rgba(255,255,255, 0.05)"
                            }
                        }}
                    >
                        Start now
                    </Button>
                </Stack>
                <IconButton size={"small"} sx={{color: grey[400]}} onClick={() => setIsOpen(false)}>
                    <IconX/>
                </IconButton>
            </Stack>
        </Box>
    )
}