import React, {useContext, useEffect, useRef, useState} from "react";
import {
    CircularProgress,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    TextField
} from "@mui/material";
import {IconCamera, IconHistory, IconSearch, IconTag} from "@tabler/icons-react";
import {Context as AppContext} from "../../context/AppContext";
import {grey} from "@mui/material/colors";
import useDebounce from "../../hooks/useDebounce";
import {useNavigate, useSearchParams} from "react-router-dom";
import Box from "@mui/material/Box";

export const Search = React.memo(() => {
    let [searchParams, setSearchParams] = useSearchParams();
    const textRef = useRef();
    const searchTerm = searchParams?.get("term");
    const [term, setTerm] = useState("");

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(textRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setTerm(searchTerm);
    }, [searchTerm])


    const onUpdateHistory = (term) => {
        let items = new Set(...localStorage.getItem("searchHistory") || []);
        items.add(term);
        localStorage.setItem("searchHistory", JSON.stringify([...items]));
        navigate(`/images?term=${term}`)
        handleClose();
    }

    return (
        <>

            <TextField
                ref={textRef}
                value={term || ""}
                onChange={(e) => {
                    setTerm(e.target.value);
                    handleClick(e);
                }}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        // Do code here
                        ev.preventDefault();
                        onUpdateHistory(term)
                    }
                }}
                size={"small"}
                placeholder={"Search"}
                autoComplete='off'
                sx={{maxWidth: "400px", width: "100%"}}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><IconSearch/></InputAdornment>,
                    sx: {
                        borderRadius: "48rem",
                        borderColor: "#4A4A4A",
                        // background: "#f1f0ea",
                        minWidth: 0,
                        background: "#fff",
                    }
                }}
            />
            <SearchMenu
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              onChange={(text) => setTerm(text)}
              searchTerm={term}
              onUpdateHistory={onUpdateHistory}
            />
        </>
    )
})

export const SearchMenu = React.memo(({open, anchorEl, handleClose, searchTerm, onUpdateHistory}) => {
    const {state: {keywords}} = useContext(AppContext);
    const debouncedSearch = useDebounce(searchTerm, 500)
    const [historySearch, setHistorySearch] = useState([]);
    const [topKeywords, setTopKeywords] = useState([]);

    useEffect(() => {
        const history = localStorage.getItem("searchHistory");
        if (history) {
            setHistorySearch(JSON.parse(history));
        }
        setTopKeywords(keywords?.slice(0, 8) || [])
    }, [])

    useEffect(() => {
        if (debouncedSearch) {
            const filter = keywords?.filter(item => item?.toLowerCase()?.includes(debouncedSearch?.toLowerCase())) || [];
            setTopKeywords(filter?.slice(0, 8))
        }
    }, [debouncedSearch])

    return (
        <Menu
            id="search-positioned-menu"
            aria-labelledby="search-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableAutoFocus
            autoFocus={false}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            MenuListProps={{
                sx: {
                    minWidth: "280px",
                    width: anchorEl && anchorEl.offsetWidth,
                    maxHeight: '40dvh',
                    overflowY: 'auto',
                    px: 1,
                    borderRadius: 5,
                }, // <-- The line that does all
                className: "scrollbar-hidden"
            }}
            slotProps={{
                paper: {sx: {borderRadius: 5, minWidth: "280px"}}
            }}
        >
            {debouncedSearch !== searchTerm && <Box sx={{textAlign: "center"}}><CircularProgress size={18} /></Box>}
            {
                topKeywords?.length ?
                    topKeywords?.map(history => <SearchItem key={`top-keywords-${history}`} text={history}
                                                            onClick={() => onUpdateHistory(history)}/>)
                    :
                    historySearch?.length > 0 && [
                        <ListItem key={"history"} sx={{p: 0.5}}>
                            <ListItemText
                                primary={"History"}
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontFamily: "monospace",
                                    fontWeight: 700,
                                    sx: {color: grey[700]}
                                }}
                            />
                        </ListItem>,
                        historySearch?.map(history => <SearchItem key={`history-${history}`} text={history}
                                                                  icon={<IconHistory size={20}
                                                                                     color={grey[700]}/>}
                                                                  onClick={() => onUpdateHistory(history)}/>)
                    ]
            }
        </Menu>

    )
})

const SearchItem = ({text, onClick, icon}) => (
    <ListItemButton onClick={onClick} sx={{p: 0.5, px: 1, borderBottom: 0.5, borderColor: "divider"}}>
        <ListItemIcon>{icon || <IconTag size={20} color={grey[700]}/>}</ListItemIcon>
        <ListItemText>{text}</ListItemText>
    </ListItemButton>
)