export const pricingInitialState = [
  {
    id: 1,
    name: "One time",
    description: "Unlimited time",
    credits: [
      { price: 27, discount: 0, credits: 3, stripePriceId: 'price_1PlZGvHbzbvcyJLvgkEWH27d' },
      { price: 87, discount: 0, credits: 10, stripePriceId: 'price_1PlZGEHbzbvcyJLvJeibSySO' },
      { price: 400, discount: 0, credits: 50, stripePriceId: 'price_1PLOHJHbzbvcyJLvi6iOutRH' },
    ],
    license: "Standard",
    type: "one-time",
    plan: "individual",
  },
  {
    id: 2,
    name: "Monthly",
    description: "No commitment, cancel anytime",
    credits: [
      { price: 30, discount: 0, credits: 3, stripePriceId: 'price_1PDOHYHbzbvcyJLv1Xf9Lf8M' },
      { price: 60, discount: 0, credits: 10, stripePriceId: 'price_1PDOHaHbzbvcyJLvUaNqcSoK' },
      { price: 150, discount: 0, credits: 50, stripePriceId: 'price_1PDOHbHbzbvcyJLv2YqzWIkc' },
    ],
    license: "Enhanced",
    type: "month",
    plan: "individual",
  },
  {
    id: 3,
    name: "Yearly",
    description: "Commitment for one year",
    credits: [
      { price: 35, discount: 0, credits: 10, stripePriceId: 'price_1PDOHeHbzbvcyJLvWJHsAsfW' },
      { price: 150, discount: 0, credits: 50, stripePriceId: 'price_1PDOHhHbzbvcyJLvi4ASJPBY' },
      { price: 360, discount: 0, credits: 200,stripePriceId: 'price_1PDOHjHbzbvcyJLviDdcVtxK' },
    ],
    license: "Enhanced",
    type: "year",
    plan: "individual",

  },
]
