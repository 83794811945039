
export const IconShoppingCartMinus = ({size}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart-minus" width={size || 24}
             height={size || 24} viewBox={`0 0 24 24`} strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
             strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
            <path d="M12.5 17h-6.5v-14h-2"></path>
            <path d="M6 5l14 1l-1 7h-13"></path>
            <path d="M16 19h6"></path>
        </svg>
    )
}