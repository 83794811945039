import React, {memo, useContext, useEffect, useRef, useState} from "react";
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  useMediaQuery
} from "@mui/material";
import {
  IconDownload,
  IconShare,
  IconShoppingCartPlus
} from "@tabler/icons-react";
import {
  DarkSecondaryIconButton,
  SecondaryIconButton
} from "../../../components/buttons/CustomIconButton";
import {SignInContext} from "../../../context/SignInContext";
import {Context as AppContext} from "../../../context/AppContext";
import {AddToCollectionButton} from "./AddToCollectionButton";
import checkIsAuth from "../../../utils/checkIsAuth";
import {handleImageDownload, shareImage} from "../../../utils/functions";
import {toast} from "react-toastify";
import {theme} from "../../../utils/Theme";
import {IconShoppingCartMinus} from "../../../components/icons/IconShoppingCartMinus";
import {CartContext} from "../../../context/CartContext";
import "./list-image.css"
import {Masonry} from "@mui/lab";

const borderRadius = "0.625rem"//"0.625rem";

export const ListImages = ({images, cols, onSelect}) => {
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  let responsiveCols = cols || 4;
  if (sm) {
    responsiveCols = 1
  } else if (md) {
    responsiveCols = 2
  }

  // return (
  //     <ImageList  sequential variant="masonry" cols={responsiveCols} gap={8} sx={{overflowY: "auto", m: 0}}>
  //       {images.map((item, index) => <ImageItem key={item.uid + index} item={item} onSelect={onSelect}/>)}
  //     </ImageList>
  // )

  return (
    <Masonry variant="masonry" columns={responsiveCols} sx={{
      "@keyframes fade-in": {
        "0%": {
          opacity: 0
        },
        "100%": {
          opacity: 1
        }
      },
      overflowY: "auto",
      m: 0,
      width: "100%",
      minHeight: "100%",
      overflowX: "hidden",
      animation: "fade-in 2500ms linear",
    }}>
      {images.map((item, index) => <ImageItem key={item.uid} item={item} onSelect={onSelect}/>)}
    </Masonry>
  )
}

const ImageItem = memo(function ({item, onSelect}) {
  const {state: {userDetails, ownedImages}, updateUserDetails} = useContext(AppContext);
  const {openModal} = useContext(SignInContext);
  const {openCartModal} = useContext(CartContext);
  const imageListItemRef = useRef(null)
  const heightRef = useRef(0);

  const [hovered, setHovered] = useState(false);
  const [height, setHeight] = useState(heightRef.current);

  const cart = userDetails?.cart || [];

  const addToCart = () => {
    try {
      const isAuth = checkIsAuth();
      if (isAuth) {
        const items = [...cart];
        items.push(item);
        updateUserDetails({...userDetails, cart: items});
        openCartModal();
        toast.success("The image has been added to your cart.");
      } else {
        openModal();
      }

    } catch (error) {
      console.log(error.message)
      openModal()
    }
  }
  const removeFromCart = () => {
    try {
      const isAuth = checkIsAuth();
      if (isAuth) {
        const items = [...cart].filter(i => i?.uid !== item?.uid);
        updateUserDetails({...userDetails, cart: items});
        toast.success("The image has been removed from your cart.");
      } else {
        openModal();
      }
    } catch (error) {
      console.log(error.message)
      openModal()
    }
  }

  const copyImage = (img) => {
    try {
      // checkIsAuth();
      shareImage(img)
    } catch (err) {
      // openModal()
    }
  }

  useEffect(() => {
    if (imageListItemRef?.current) {
      let ratio = 1
      if (typeof item.ratio === "string") {
        const stringRatio = item.ratio.replace("(", "").replace(")", "").replace(" ", "").split(",")
        const ratio0 = +stringRatio[0]
        const ratio1 = +stringRatio[1]
        ratio = ratio1 / ratio0
      } else {
        ratio = item.ratio[1] / item.ratio[0]
      }
      setHeight(imageListItemRef?.current?.clientWidth * ratio)
    }
  }, [imageListItemRef?.current]);


  useEffect(() => {
    heightRef.current = height
  }, [height]);

  const isItemInCart = cart?.find(i => i?.uid === item?.uid);
  const isOwned = ownedImages?.find(i => i?.uid === item?.uid);

  return (
    <Box
      className="list-image"
      ref={imageListItemRef}
      key={item.imageUrl}
      sx={{cursor: "pointer", height: height > 0 ? `${height}px !important` : "auto"}}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <ImageListItemBar
        title={item.title}
        subtitle={item.author}
        position={"top"}
        sx={{bgcolor: "transparent", zIndex: 3}}
        actionIcon={
          <Stack direction={"row"} justifyContent={"flex-end"} sx={{width: "100%"}}>
            {
              hovered &&
              <>
                <AddToCollectionButton image={item}/>
                <DarkSecondaryIconButton
                  title={"Share"}
                  onClick={() => copyImage(item)}
                  selected={false}
                >
                  <IconShare size={18}/>
                </DarkSecondaryIconButton>
              </>
            }
            {
              isOwned ?
                <SecondaryIconButton
                  title={"Download image"}
                  onClick={() => handleImageDownload(item)}
                  selected={false}
                >
                  <IconDownload size={18}/>
                </SecondaryIconButton>
                :
                isItemInCart ?
                  <SecondaryIconButton
                    title={"Remove from cart"}
                    onClick={removeFromCart}
                    selected={false}
                  >
                    <IconShoppingCartMinus size={18}/>
                  </SecondaryIconButton>
                  :
                  hovered &&
                  <DarkSecondaryIconButton
                    title={"Add to cart"}
                    onClick={addToCart}
                    selected={false}
                  >
                    <IconShoppingCartPlus size={18}/>
                  </DarkSecondaryIconButton>
            }
          </Stack>
        }
      />
      <img
        onClick={() => onSelect(item)}
        // srcSet={`${item.imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
        src={`${item.imageUrl}?w=248&fit=crop&auto=format`}
        style={{width: "100%", height: "100%", objectFit: "cover"}}
        // alt={item.title}
        loading="lazy"
      />
    </Box>
  )
})