import React, {useContext} from "react";
import Box from "@mui/material/Box";
import {List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
// import logo from "../../assets/img/logo.png"
import logoWithText from "../../assets/img/logo-with-text-white.png"
import {Link} from "react-router-dom";
import {SignInContext} from "../../context/SignInContext";

export const Footer = () => {
    const {openModal} = useContext(SignInContext);

    return (
        <Box sx={{width: "100vw", bgcolor: "#220634", py: 4, minHeight: "25dvh"}}>
            <Stack direction={"row"} useFlexGap flexWrap={"wrap"} gap={2} justifyContent={"space-between"} sx={{width: "80vw", mx: "auto"}}>
                <Box>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                        {/*<img src={logo} alt={"Logo"} width={60} height={60}/>*/}
                        <img src={logoWithText} alt={"Logo"} maxWidth={60} height={45}/>
                        {/*<Typography fontWeight={"bold"} color={"#FFF"} fontSize={"1.375rem"}>Sura صورة</Typography>*/}
                    </Stack>
                    <Typography variant={"subtitle2"} color={"#BCBCBC"} sx={{pt: 2}}>© {new Date().getUTCFullYear()} Sura Photos</Typography>
                </Box>
                <Stack direction={"row"} useFlexGap flexWrap={"wrap"} gap={1}>
                    <List sx={{color: "#FFF"}}>
                        <ListItem sx={{py: 1}}>
                            <ListItemText><Typography fontWeight={"bold"}>Get Started</Typography></ListItemText>
                        </ListItem>
                        <ListItem sx={{py: 1}}>
                            <ListItemText><Typography sx={{cursor: "pointer"}} onClick={() => openModal()}>Sign up</Typography></ListItemText>
                        </ListItem>
                        <ListItem sx={{py: 1}}>
                            <ListItemText><Link style={{ color: "#FFF", textDecoration: "none" }} to={"/enterprise"} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Enterprise Demo</Link></ListItemText>
                        </ListItem>
                        <ListItem sx={{py: 1}}>
                            <ListItemText><Link style={{ color: "#FFF", textDecoration: "none" }} to={"/request-image"} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Request an image</Link></ListItemText>
                        </ListItem>
                        <ListItem sx={{py: 1}}>
                            <ListItemText><Link style={{ color: "#FFF", textDecoration: "none" }} to={"/pricing"} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Pricing</Link></ListItemText>
                        </ListItem>
                    </List>
                    {/*<List sx={{color: "#FFF"}}>*/}
                    {/*    <ListItem>*/}
                    {/*        <ListItemText><Typography fontWeight={"bold"}>Legal</Typography></ListItemText>*/}
                    {/*    </ListItem>*/}
                    {/*    <ListItem>*/}
                    {/*        <ListItemText><Link style={{ color: "#FFF", textDecoration: "none" }} to={"/terms"}>Terms of use</Link></ListItemText>*/}
                    {/*    </ListItem>*/}
                    {/*    <ListItem>*/}
                    {/*        <ListItemText><Link style={{ color: "#FFF", textDecoration: "none" }} to={"/policy"}>Privacy policy</Link></ListItemText>*/}
                    {/*    </ListItem>*/}
                    {/*</List>*/}
                </Stack>
            </Stack>
        </Box>
    )
}