import * as React from "react";
import {PlasmicEnterpriseSection} from "./plasmic/saudi_shutterstock/PlasmicEnterpriseSection";
import Airtable from 'airtable';
import {useState, useEffect} from "react";

function EnterpriseSection_(props, ref) {
    const [contactForm, setContactForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: ''
    });
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [ipData, setIpData] = useState('');

    const getIp = async () => {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setIpData(JSON.stringify(data));
    }
    useEffect(() => {
        getIp();
    }, []);

    Airtable.configure({
        endpointUrl: 'https://api.airtable.com',
        apiKey: "patnJLPCDLqdnvwMr.dd60f95e563b8d17bc5ae79f59c36d70511bd33dc155d1893acd28eb22e6bfd2"
    });
    const base = Airtable.base('appWt2Ou3hHYFOMhd');

    async function sendDataToAirTable() {
        const fields = {
            firstName: contactForm.firstName || '',
            lastName: contactForm.lastName || '',
            email: contactForm.email || '',
            phone: contactForm.phone || '',
            companyName: contactForm.companyName || '',
            ip: ipData || '',
            time: new Date(Date.now()).toLocaleString()
        };

        setLoading(true);
        await base('enterprise contact form').create([{fields}], function (err, records) {
            setLoading(false);
            if (err) {
                console.error(err);
                return;
            }
            setSubmitted(true);
        });
    }

    const onChange = (e) => {
        const {name, value} = e.target;
        setContactForm(prev => ({...prev, [name]: value}));
    }


    console.log(contactForm)

    return (
        <PlasmicEnterpriseSection
            root={{ref}}
            {...props}
            loading={loading}
            submitted={submitted}
            firstName={{
                value: contactForm.firstName,
                onChange: (e) => {
                    setContactForm(prev => ({...prev, firstName: e.target.value}))
                },
            }}
            lastName={{
                value: contactForm.lastName,
                onChange: (e) => {
                    setContactForm(prev => ({...prev, lastName: e.target.value}))
                },
            }}
            email={{
                value: contactForm.email,
                onChange: (e) => {
                    setContactForm(prev => ({...prev, email: e.target.value}))
                },
            }}
            phone={{
                value: contactForm.phone,
                onChange: (e) => {
                    setContactForm(prev => ({...prev, phone: e.target.value}))
                },
            }}
            companyName={{
                value: contactForm.companyName,
                onChange: (e) => {
                    setContactForm(prev => ({...prev, companyName: e.target.value}))
                },
            }}

            submitButton={{
                onClick: (e) => {
                    e.preventDefault(); // Prevent default form submission
                    setLoading(true)
                    sendDataToAirTable()
                },
                loading: loading,
                submitted: submitted
            }}
        />
    );
}

const EnterpriseSection = React.forwardRef(EnterpriseSection_);

export default EnterpriseSection;
