// Example usage:
export const fetchImages = async () => {
    try {
        const response = await fetch(
            `https://swell-attack-production.up.railway.app/getAllImagesLowRes`
        );
        const data = await response.json();
        const images = data?.map(img => {
            return {
                ...img,
                imageUrl: img["low_res_img_url"] || img?.url || "",
                tags: typeof img?.tags === "string" ? JSON.parse(img?.tags?.replaceAll("'", "\"") || "") : img?.tags || [],
                dpi: typeof img?.dpi === "string" ? JSON.parse(img?.dpi?.replaceAll("'", "\"") || "") : img?.dpi || [],
            }
        });
        return images;
    } catch (error) {
        console.error("Error fetching images:", error);
        return [];
    }
}

export const getStripeCheckoutSession = async (priceId, userUid, userEmail, paymentRecurrence, productName, numberOfCredits) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + `getCheckoutSession`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    priceId,
                    userUid,
                    userEmail,
                    paymentRecurrence,
                    productName,
                    numberOfCredits
                }),
            }
        );
        const sessionUrl = await response.json();
        return sessionUrl;
    } catch (error) {
        console.error("Error fetching images:", error);
        return [];
    }
}
