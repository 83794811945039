import React from 'react';
import {Modal, Box, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "sm", maxWidth: "md", width: "90%",
  bgcolor: "background.paper",
  boxShadow: 10,
  borderRadius: 5,
  px: {xs: 2, md: 4},
  py: {xs: 3, md: 4}
};

const ReusableModal = ({open, handleClose, children, ...props}) => {
  return (
    <Modal open={open} onClose={handleClose} sx={{backdropFilter: "blur(4px)"}}>
      <Box sx={props?.sx ? {...modalStyle, ...props.sx} : modalStyle}>
        <IconButton onClick={handleClose} sx={{position: "absolute", top: 7, right: 7, zIndex: 10000}}>
          <CloseIcon/>
        </IconButton>
        {children}
      </Box>
    </Modal>
  );
};

export default ReusableModal;