import React, {useMemo, useRef} from "react";
import {Box, Stack, Toolbar} from "@mui/material";
import {Header} from "./header/Header";
import "./main.css"
import {Footer} from "../../components/footer/Footer";

export const MainLayout = ({children, hideFooter = false}) => {

  const mainRef = useRef(null);

  const height = useMemo(() => {
    if (mainRef.current) {
      return `${mainRef.current.offsetHeight}px`;
    }

    return '100%';
  }, [mainRef.current])

  return (
    <>
      <Header/>
      <Toolbar sx={{height: {xs: 64, md: 64}}}/>
      {children}
      {!hideFooter && <Footer/>}
    </>
  )
}