import React, {useContext, useEffect, useMemo, useState} from "react";
import {Avatar, Fade, IconButton, MenuItem, Select, Stack, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  IconTrash,
  IconX
} from "@tabler/icons-react";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";
import {Context as AppContext} from "../../context/AppContext";
import Autocomplete from "@mui/material/Autocomplete";
import {getDocs, query, where} from "firebase/firestore";
import {usersCollection} from "../../db/collections";
import {toast} from "react-toastify";
import {auth} from "../../config/firebase";
import Button from "@mui/material/Button";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vw",
  maxWidth: "400px",
  height: "auto",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: "0.75rem",
  border: '0px solid #000',
  boxShadow: 24,
  p: 3,
  overflowY: "auto"
};

export const CreateCollectionModal = ({open, handleClose, selectedCollection}) => {
  const {state: {collections}, createCollection, updateCollection} = useContext(AppContext);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    images: [],
    members: selectedCollection?.members || {
      [auth.currentUser.uid]: {
        displayName: auth?.currentUser?.displayName,
        email: auth?.currentUser?.email,
        photoUrl: auth?.currentUser?.photoURL,
        role: "editor"
      }
    }
  });

  useEffect(() => {
    if (open) {
      if (selectedCollection) {
        setFormData({
          uid: selectedCollection?.uid,
          name: selectedCollection?.name,
          description: selectedCollection?.description,
          images: selectedCollection?.images || [],
          members: selectedCollection?.members || {
            [auth.currentUser.uid]: {
              displayName: auth?.currentUser?.displayName,
              email: auth?.currentUser?.email,
              photoUrl: auth?.currentUser?.photoURL,
              role: "editor"
            }
          }        })
      } else {
        setFormData({
          name: "",
          description: "",
          images: [],
          members: {
            [auth.currentUser.uid]: {
              displayName: auth?.currentUser?.displayName,
              email: auth?.currentUser?.email,
              photoUrl: auth?.currentUser?.photoURL,
              role: "editor"
            }
          }
        })
      }
    }
  }, [selectedCollection, open]);
  
  const onSubmit = () => {
    if (!formData?.name || !formData?.description) {
      return;
    }
    try {

      if (formData?.uid) {
        updateCollection(formData?.uid, formData);
      } else {
        createCollection(formData?.name, formData?.description, formData?.images, formData?.members);
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleClose();
    }
  }

  const members = useMemo(() => {
    return collections?.length ? collections?.map(collection => collection?.members || [])?.flat() : []
  }, [collections])

  const hasPermission = !selectedCollection || selectedCollection?.userUid === auth.currentUser.uid;

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => handleClose()}
    >
      <Fade in={open} timeout={500}>
        <Stack direction={"column"} gap={3} sx={style}>
          <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pb: 1, borderBottom: 1, borderColor: "divider"}}>
            <Typography variant={"h4"} fontWeight={700}>{selectedCollection ? "Update collection" : "Create new collection"}</Typography>
            <IconButton
              size={"small"}
              onClick={handleClose}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Stack>
          <TextField
            label={"Name"}
            value={formData?.name}
            InputLabelProps={{shrink: true}}
            placeholder={"Name your collection..."}
            onChange={e => setFormData(prev => ({...prev, name: e.target.value}))}
          />
          <TextField
            label={"Description"}
            value={formData?.description}
            multiline
            InputLabelProps={{shrink: true}}
            placeholder={"Describe your collection here..."}
            rows={3}
            onChange={e => setFormData(prev => ({...prev, description: e.target.value}))}
          />
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            clearOnBlur
            onBlur={async (e) => {
              const value = e.target.value;
              if (!value) return;

              try {

                const q =  query(usersCollection, where("email", "==", value));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {

                  let user = null;
                  querySnapshot.forEach(doc => {
                    user = doc.data();
                  });
                  const {uid, displayName, email, photoUrl} = user;
                  const exist = formData.members[uid];
                  if (!exist) {
                    setFormData(prev => (
                      {
                        ...prev,
                        members: {
                          ...prev.members,
                          [uid]: {
                            uid: uid,
                            displayName: displayName || "",
                            email: email || "",
                            photoUrl: photoUrl || "",
                            role: "viewer"
                          }
                        }
                      }
                    ))
                  }
                } else {
                  toast.error(`User with ${value} not found.`);
                }
              } catch (error) {
                console.log(error);
                toast.error(`Failed to find user with mail ${value}.`);
              }
            }}
            options={members.map((option) => option?.name || option?.email)}
            renderInput={(params) => <TextField {...params} type={"email"} label="Add collaborators" InputLabelProps={{shrink: true}} placeholder={"Type email here..."} />}
          />
          <Stack direction={"column"}>
            {Object.values(formData?.members)?.map((member, index) => {
              return <Stack  key={member?.email} direction={"row"} alignItems={"center"} gap={1} sx={{py: 1, borderBottom: 1, borderColor: "divider"}}>
                <Avatar src={member?.photoUrl} alt={"member"} sx={{width: 25, height: 25, fontSize: 12}}>
                  {!member?.photoUrl && member?.displayName?.slice(0,1)?.toUpperCase()}
                </Avatar>
                <Typography sx={{flex: 1}}>
                  {member?.displayName || member?.email}
                </Typography>
                <Select
                  value={member?.role}
                  size={"small"}
                  variant={"standard"}
                  sx={{minWidth: "100px"}}
                  onChange={(e) => {
                    setFormData(prev => {
                      const newMembers = {...prev.members} || {};
                      newMembers[member.uid].role = e.target.value;
                      return {
                        ...prev,
                        members: newMembers
                      }
                    })
                  }}
                >
                  <MenuItem value={"editor"}>Can edit</MenuItem>
                  <MenuItem value={"viewer"}>Can view</MenuItem>
                </Select>
                <IconButton
                  disabled={!hasPermission || member?.email !== auth.currentUser?.uid}
                  color={"error"}
                  onClick={() => {
                    setFormData(prev => {
                      const newMembers = {...prev.members};
                      delete newMembers[member.uid];
                      return {
                        ...prev,
                        members: newMembers
                      }
                    })
                  }}
                >
                  <IconTrash size={18} />
                </IconButton>
              </Stack>
            })}
          </Stack>
          <Stack direction={"row"} gap={1}>
            <Button
              color={"secondary"}
              variant={"contained"}
              onClick={onSubmit}
              disabled={!formData?.name || !formData?.description}
              sx={{borderRadius: 5, fontSize: 14, textTransform: "none"}}
            >
              Save
            </Button>
            <Button
              color={"secondary"}
              variant={"outlined"}
              onClick={handleClose}
              sx={{borderRadius: 5, fontSize: 14, textTransform: "none"}}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  )
}
