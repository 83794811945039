import React, {useContext, useState} from "react";
import {MainLayout} from "../../layouts/main-layout/MainLayout";
import {ImageList, ImageListItem, Stack, Toolbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import logo from "../../assets/img/logo.png"
import Button from "@mui/material/Button";
import {deepPurple} from "@mui/material/colors";
import {IconChevronRight} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {ListImages} from "../browse-images/components/ListImages";
import {Image} from "../../components/image/Image";
import {Context as AppContext} from "../../context/AppContext";
import {Footer} from "../../components/footer/Footer";

export const Mission = () => {

  return (
    <MainLayout>
      <Box
        className={"mission-page"}
        sx={{
          // maxHeight: "calc(100vh - 64px)",
          height: "100%",
          // overflowY: "auto",
          overflowX: "hidden",
          bgcolor: "#FFF"
        }}>
        <StoryTelling/>

        <MissionInformation/>

        <MissionImages/>
      </Box>
    </MainLayout>
  )
}

const StoryTelling = () => {
  return (
    <Stack
      direction={"column"} gap={2}
      alignItems={"center"} justifyContent={"center"}
      sx={{width: "100%", height: "100%"}}
    >
      <Toolbar/>
      <Typography sx={{fontSize: {xs: "2rem", md: "3.125rem"}, py: 2}} fontWeight={"bold"} align={"center"}>Storytelling
        without boundaries</Typography>
      <Typography fontStyle={"italic"} align={"center"} sx={{pb: 5}}>A comprehensive collection of high-quality,
        culturally resonant images, <br/>helping to <b style={{color: "#5034F0"}}>share the heart and soul</b> of the Arab world.</Typography>
      <Stack
        direction={"column"}
        sx={{width: {xs: "80vw", md: "50vw"}}}
      >
        <img
          src={"story-telling.png"}
          alt={"sura"} style={{width: 'auto'}} align={"center"}/>
      </Stack>
      <Toolbar/>
    </Stack>
  )
}

const MissionInformation = () => {
  return (
    <Stack
      className={"mission-bg"}
    >
      <Stack
        className={"mission-bg-child"}
        direction={"column"} gap={3}
        alignItems={"center"} justifyContent={"center"}
        sx={{width: "100%", height: "100dvh"}}
      >
        <img src={logo} alt={"Girl"} style={{width: "150px", height: "150px"}} align={"center"}/>
        <Typography sx={{fontSize: {xs: "1.2rem", md: "1.875rem"}, maxWidth: "80vw", px: 2}} fontWeight={"bold"}
                    align={"center"}>
          Sura صورة empowers creators with authentic visuals. Our curated collection of
          stock
          images honors the rich tapestry of cultures, landscapes, and traditions across the Arab world.
        </Typography>

        <Typography sx={{fontSize: {xs: "1rem", md: "1.3rem"}, maxWidth: "80vw", px: 2}} fontStyle={"italic"}
                    align={"center"}>
          Our mission is to curate a diverse collection of genuine images that illuminate
          pathways where global creativity meets the rich tapestry of Arab heritage.
        </Typography>
      </Stack>

    </Stack>
  )
}

const MissionImages = () => {
  const {state: {images}} = useContext(AppContext);

  const [selected, setSelected] = useState("culture");

  return (
    <Stack direction={"column"} gap={3} sx={{width: "80vw", mx: "auto", p: 3}}
           alignItems={"center"}>
      <Box sx={{width: "80vw"}}>
        <Typography sx={{fontSize: {xs: "1.8rem", md: "2.6875rem"}, pb: 2}} fontWeight={"bold"} align={"left"}>
          Transforming the landscape of visual storytelling for lifestyle and culture.
        </Typography>
      </Box>
      {/*<Stack direction={"row"} justifyContent={"space-between"} gap={1} sx={{width: "100%"}}>*/}
      {/*  <Stack direction={"row"} gap={1}>*/}
      {/*    <Button*/}
      {/*      sx={{*/}
      {/*        borderBottom: 2,*/}
      {/*        borderColor: selected === "culture" ? deepPurple[500] : "transparent",*/}
      {/*        borderRadius: 0,*/}
      {/*        color: deepPurple[500],*/}
      {/*        textTransform: "capitalize"*/}
      {/*      }}*/}
      {/*      onClick={() => setSelected("culture")}*/}
      {/*    >*/}
      {/*      Culture*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      sx={{*/}
      {/*        borderBottom: 2,*/}
      {/*        borderColor: selected === "lifestyle" ? deepPurple[500] : "transparent",*/}
      {/*        borderRadius: 0,*/}
      {/*        color: deepPurple[500],*/}
      {/*        textTransform: "capitalize"*/}
      {/*      }}*/}
      {/*      onClick={() => setSelected("lifestyle")}*/}
      {/*    >*/}
      {/*      Lifestyle*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      sx={{*/}
      {/*        borderBottom: 2,*/}
      {/*        borderColor: selected === "future" ? deepPurple[500] : "transparent",*/}
      {/*        borderRadius: 0,*/}
      {/*        color: deepPurple[500],*/}
      {/*        textTransform: "capitalize"*/}
      {/*      }}*/}
      {/*      onClick={() => setSelected("future")}*/}
      {/*    >*/}
      {/*      Future*/}
      {/*    </Button>*/}
      {/*  </Stack>*/}


      {/*  <Button*/}
      {/*    component={Link}*/}
      {/*    to={`/images?type=${selected}`}*/}
      {/*    sx={{*/}
      {/*      border: 1,*/}
      {/*      borderColor: deepPurple[500],*/}
      {/*      borderRadius: 5,*/}
      {/*      color: deepPurple[500],*/}
      {/*      textTransform: "none",*/}
      {/*      px: 2,*/}
      {/*      display: {xs: "none", md: "flex"}*/}
      {/*    }}*/}
      {/*    endIcon={<IconChevronRight/>}*/}
      {/*    onClick={() => setSelected("lifestyle")}*/}
      {/*  >*/}
      {/*    Explore our {selected} collection*/}
      {/*  </Button>*/}
      {/*</Stack>*/}

      <ImageList variant="masonry" cols={3} gap={8} sx={{m: 0}}>
        {images?.slice(0, 6).map((item, index) => {
          return (
            <ImageListItem
              sx={{
                borderRadius: 0,
                p: 0,
              }}
            >
              <Image
                url={item.imageUrl}
                name={item.name}
                style={{zIndex: -1, borderRadius: 0}}
                loading="lazy"
              />
            </ImageListItem>
          )
        })}
      </ImageList>
    </Stack>
  )
}