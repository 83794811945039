import React, {useContext, useEffect, useMemo, useState} from "react";
import {MainLayout} from "../../layouts/main-layout/MainLayout";
import Typography from "@mui/material/Typography";
import {Chip, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Switch} from "@mui/material";
import {grey} from "@mui/material/colors";
import Box from "@mui/material/Box";
import {IconCheck, IconChecks} from "@tabler/icons-react";
import Button from "@mui/material/Button";
import {pricingInitialState} from "../../consts/pricingInitialState";
import {Context as AppContext} from "../../context/AppContext";
import {auth} from "../../config/firebase";
import {toast} from "react-toastify";
import {getStripeCheckoutSession} from "../../utils/APIs";
import checkIsAuth from "../../utils/checkIsAuth";
import {SignInContext} from "../../context/SignInContext";
import useConfirm from "../../hooks/useConfirm";
import {useNavigate} from "react-router-dom";
import {PlansRepository} from "../../repository/PlansRepository";

const PlanType = {
  INDIVIDUAL: "individual",
  ENTERPRISE: "enterprise",
}
export const Pricing = () => {
  const {state: {userDetails}, updateSubscription} = useContext(AppContext)
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const items = await PlansRepository.getAll();
        setPlans(items);
      } catch (e) {
        console.log(e);
      }
    }
    fetchPlans();
  }, [])

  return (
    <MainLayout>
      <Stack gap={4} alignItems={"center"} justifyContent={"center"} sx={{position: "relative", minHeight: "calc(100dvh - 64px)", padding: "16px"}}>
        <Box className={"mission-bg"}
             sx={{position: "absolute", top: 0, left: 0, zIndex: -1, width: "100%", height: "100%"}}/>
        <Box sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          width: "100%",
          height: "100%",
          background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%)"
        }}/>


        <Stack
          maxWidth={"md"}
          gap={2}
          direction={"column"}
          sx={{
            textAlign: "center",
            maxWidth: "600px",
            margin: "40px auto 0",
          }}
        >
          <Typography variant={"h1"} fontWeight={700}>Fair, No-Nonsense Pricing</Typography>
          <Typography fontWeight={400}>Our mission is to fill digital content and billboards with images that
            resonate
            with the heart and soul of the Arab world.</Typography>
          <Typography fontWeight={400}>Therefore, we made pricing simple and lower than any other stock image platform.
            {/*If you want to read how we rank compared to other royalty-free image platforms, click here.*/}
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={1} justifyContent={'center'} alignItems={"center"} style={{margin:"30px auto 0"}}>
          <Typography fontWeight={700}>Individual</Typography>
          <Switch
            onClick={() => navigate("/enterprise")}
          />
          <Typography fontWeight={700}>Enterprise</Typography>
        </Stack>
        <Grid container maxWidth={"lg"} sx={{pt: 4}} style={{margin: "40px auto 80px auto"}} >
          {
            plans
              .map(planObject => {
                return <PricingItem plan={planObject} key={planObject.id} recurrence={planObject.type} prices={planObject.credits} user={userDetails}/>;
              })
          }
        </Grid>
      </Stack>
    </MainLayout>
  )
}

const PricingItem = ({plan, user}) => {
  const [selectedPrice, setSelectedPrice] = useState(null);
  const {openModal} = useContext(SignInContext);
  const [Dialog, confirmPlan] = useConfirm("You are about to purchase a new plan. If you are changing subscriptions, your current plan will be canceled and the new plan will activate ⚡️.");

  useEffect(() => {
    let credit = plan?.credits[0] || null;
    if (user?.subscription) {
      credit = plan?.credits?.find(i => i.stripePriceId === user?.subscription?.priceId) || credit;
    }
    setSelectedPrice(credit || null);
  }, [plan, user])


  const onChoosePlan = async () => {
    if (!checkIsAuth()) {
      openModal()
      return
    }
    if (user?.subscription?.priceId) {
      const response = await confirmPlan();
      if (!response) {
        return;
      }
    }
    try {
      const chosenPlan = {
        ...plan,
        ...selectedPrice
      }

      if (selectedPrice?.type === PlanType.ENTERPRISE) {
        chosenPlan.team = [
          {userId: auth.currentUser.uid, canPurchase: true, canDownload: true, role: "admin"}
        ];
      }
      toast.info('Redirecting you to stripe checkout page')
      const stripeCheckoutPage = await getStripeCheckoutSession(
        selectedPrice.stripePriceId,
        auth.currentUser.uid,
        auth.currentUser.email,
        chosenPlan.type,
        `${chosenPlan.name} - ${chosenPlan.description}`,
        selectedPrice.credits
      )
      window.location.replace(stripeCheckoutPage)

      // await updateSubscription(chosenPlan);
      // toast.success("Successfully purchased new plan");
    } catch (err) {
      toast.error("Failed to purchase plan");
    }
  }

  const isSelectedPlan = plan?.credits?.map(credit => credit.id)?.includes(user?.subscription?.priceId)
  const isSelectedPrice = selectedPrice?.stripePriceId === user?.subscription?.priceId;

  const creditsMap = useMemo(() => {
    return plan?.credits?.map(credit => {
      const isSelected = credit?.stripePriceId === selectedPrice?.stripePriceId;

      return (
        <Chip
          variant={isSelected ? "contained" : "outlined"}
          color={"secondary"}
          key={`${credit?.credits}-${credit?.price}`}
          label={credit?.credits}
          disabled={isSelected && isSelectedPrice && plan?.type !== "one-time"}
          onClick={() => setSelectedPrice(credit)}
        />
      )
    })
  }, [plan, selectedPrice])

  return (
    <Grid
      item xs={12} sm={12} md={4}
      sx={{
        display: "flex", justifyContent: "center",
        p: {xs: 0, md: 3, lg: 5},
        py: {xs: 2, md: 0, lg: 0}
    }}
    >
      <Stack
        direction={"column"}
        sx={{
          border: 1,
          borderColor: "divider",
          flex: 1,
          maxWidth: 260,
          gap: 2,
          boxShadow: 3,
          m: {xs: "auto", md: 1},
          p: 3,
          textAlign: "center",
          bgcolor: "#FFF",
        }}
      >
        <Dialog/>
        <Typography variant={"h3"}
                    fontWeight={400}
                    sx={{color: grey[600]}}
                    textTransform={"uppercase"}
        >
          {plan?.name}
        </Typography>
        <Stack direction={"row"} alignItems={"flex-end"} justifyContent={"center"}>
          <Typography variant={"h2"} fontWeight={800}
                      sx={{color: grey[700]}}>${selectedPrice?.price || "0"}</Typography>
          <Typography sx={{color: grey[500]}} fontWeight={700}>/{plan?.type}</Typography>
        </Stack>
        <Typography fontWeight={400}
                    sx={{color: grey[600]}}
        >
          {plan?.description}
        </Typography>

        <Stack direction={"column"} gap={1} sx={{mt: 'auto'}}>
          <Typography fontWeight={400} sx={{color: grey[600]}}>
            Photos:
          </Typography>
          <Stack direction={"row"} gap={1} justifyContent={"center"} useFlexGap flexWrap={"wrap"}>

            {creditsMap}
          </Stack>
          <Button
            variant={"outlined"}
            color={isSelectedPlan && plan?.type === "one-time" ? "secondary" : "primary"}
            disabled={isSelectedPrice && plan?.type !== "one-time"}
            onClick={onChoosePlan}
          >
            {isSelectedPrice ? plan?.type === "one-time" ? "Buy Again" : "Your plan" : "Select plan"}
          </Button>
        </Stack>
      </Stack>
    </Grid>
  )
}
