export const freeSubscription = {
  createdAt: null,
  numberOfCredits: 0,
  priceId: null,
  product: {
    credits: 0,
    discount: 0,
    price: 0,
    productId: null,
    stripePriceId: null,
    status: "complete",
    timestamp: null,
    userUid: null
  }
}