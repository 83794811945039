import React, {useContext} from "react";
import {IconHeartFilled} from "@tabler/icons-react";
import {DarkSecondaryIconButton} from "../../../components/buttons/CustomIconButton";
import CollectionMenu from "./CollectionMenu";
import checkIsAuth from "../../../utils/checkIsAuth";
import {SignInContext} from "../../../context/SignInContext";

export const AddToCollectionButton = ({image}) => {
  const {openModal} = useContext(SignInContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    try {

      const isAuth = checkIsAuth();
      if (isAuth) {
        setAnchorEl(event.currentTarget);
      } else {
        openModal();
      }
    } catch (err) {
      openModal();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <DarkSecondaryIconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <IconHeartFilled size={18}/>
      </DarkSecondaryIconButton>
      <CollectionMenu open={open} image={image} handleClose={handleClose} anchorEl={anchorEl} checkIsAuth={checkIsAuth}/>
    </>
  )
}