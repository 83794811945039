import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {MainLayout} from "../../layouts/main-layout/MainLayout";
import {FreeImageSelectionBanner} from "../../components/banners/FreeImageSelectionBanner";
import Box from "@mui/material/Box";
import {Chip, CircularProgress, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {
  IconAdjustments, IconStarFilled
} from "@tabler/icons-react";
import {Filter} from "./components/Filter";
import {deepPurple, grey} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import {useParams, useSearchParams} from "react-router-dom";
import {ListImages} from "./components/ListImages";
import {Context as AppContext} from "../../context/AppContext";
import {ImageModal} from "./ImageModal";
import {Square} from "@mui/icons-material";
import {useSetAtom} from "jotai";
import {ContactFormAtom} from "../../atoms";
import {SubscriptionType} from "../../utils/enums";

export const BrowseImages = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const setContactModalAtom = useSetAtom(ContactFormAtom);
  const searchTerm = searchParams?.get("term");

  const bannerRef = useRef(null);
  const {id} = useParams();

  const [selectedImage, setSelectedImage] = useState(null);
  const [nextImage, setNextImage] = useState(null);
  const [prevImage, setPrevImage] = useState(null);

  const [openFilter, setOpenFilter] = useState(false);
  const [filterParams, setFilterParams] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [pagination, setPagination] = useState(40);
  const {state: {images, userDetails}} = useContext(AppContext)

  useEffect(() => {
    let next = null;
    let prev = null;
    if (selectedImage && filteredImages?.length > 0) {
      const index = filteredImages?.findIndex(img => img?.uid === selectedImage?.uid);
      if (isFinite(index) && index >= 0) {
        next = filteredImages[index + 1] || null;
        prev = filteredImages[index - 1] || null;
      }
    }
    setNextImage(next);
    setPrevImage(prev)
  }, [selectedImage])

  useEffect(() => {
    let newImages = [...images];
    if (filterParams && filterParams.length) {
      for (let filter of filterParams) {
        newImages = newImages?.filter(img => filter?.func(filter?.value, img))
      }
    }
    if (searchTerm) {
      newImages = newImages.filter(item => item.tags.filter(tag => tag?.toLowerCase()?.includes(searchTerm)).length > 0);
    }
    setFilteredImages(newImages);
  }, [images, searchTerm, filterParams])

  useEffect(() => {
    if (id) {
      const img = filteredImages?.find(item => item?.uid === id);
      if (img) {
        setSelectedImage(img);
      }
    }
  }, [id, filteredImages])

  const removeFilterParam = (param) => {
    setFilterParams(prevState => {
      return prevState.filter(item => item.value !== param.value);
    })
  }

  const listImages = useMemo(() => {
    return <ListImages
      images={filteredImages.slice(0, pagination)}
      cols={4}
      onSelect={(img) => setSelectedImage(img)}
    />
  }, [filteredImages, pagination])

  const openContactModal = (title, message) => {
    setContactModalAtom({open: true, title, message})
  }

  return (
    <MainLayout hideFooter>
      <ImageModal
        image={selectedImage}
        next={nextImage}
        prev={prevImage}
        handleClose={() => setSelectedImage(null)}
        open={!!selectedImage}
        onSelect={(img) => setSelectedImage(img)}
      />
      <Box ref={bannerRef} sx={{width: '100%', display: {xs: "none", md: "block"}}}>
        <FreeImageSelectionBanner/>
      </Box>

      <Stack direction={"row"} gap={1} sx={{p: 1}}>
        <Filter
          onClose={() => setOpenFilter(!openFilter)}
          open={openFilter}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
        <Box className={"scrollbar-hidden"} sx={{flex: 1, maxHeight: {xs: "calc(100dvh - 64px)", md: "calc(100dvh - 80px)"}, height: '100%', overflowY: "auto"}}>
          <Stack direction={"row"} sx={{py: 1}} gap={2} justifyContent={"flex-start"}
                 alignItems={"center"}>
            <Button
              variant={"outlined"}
              color={"secondary"}
              size={"small"}
              sx={{
                borderRadius: 5,
                textTransform: "capitalize",
                color: deepPurple[500],
                fontSize: 14,
                fontWeight: 800,
                px: 2
              }}
              onClick={() => setOpenFilter(!openFilter)}
              endIcon={<IconAdjustments size={20}/>}
            >
              {openFilter ? "Hide Filters" : "Filters"}
            </Button>
            {
              userDetails?.subscription?.type !== SubscriptionType.ENTERPRISE &&
              <>
                <Box sx={{position: "relative"}}>
                  <Chip
                    label={"Saudi"} variant={"outlined"} color={"secondary"} sx={{px: 1, color: deepPurple[500], fontSize: 14, fontWeight: 800}}
                    onClick={() => openContactModal("Interested in photo collections of specific GCC countries?", `I am interested in photo collections in "Saudi".`)}
                  />
                  <IconStarFilled size={18} style={{color: "#FFBC50", position: "absolute", top: -5, right: -5}} />
                </Box>
                <Box sx={{position: "relative"}}>
                  <Chip
                    label={"Emirates"} variant={"outlined"} color={"secondary"} sx={{px: 1, color: deepPurple[500], fontSize: 14, fontWeight: 800}}
                    onClick={() => openContactModal("Interested in photo collections of specific GCC countries?", `I am interested in photo collections in "Emirates".`)}
                  />
                  <IconStarFilled size={18} style={{color: "#FFBC50", position: "absolute", top: -5, right: -5}} />
                </Box>
              </>
            }
          </Stack>
          {filterParams?.length ? <Stack direction={"row"} gap={1} sx={{pb: 1, pt: 0}}
                                         alignItems={"center"} flexWrap={"wrap"} useFlexGap>
            {
              filterParams?.map(param => {
                return {
                  "choice": <Chip key={param?.value} label={param?.value} size={"small"} sx={{textTransform: "capitalize"}} variant={"outlined"}
                                  onDelete={() => removeFilterParam(param)}/>,
                  "color": <Chip key={param?.type} variant={"outlined"} size={"small"} sx={{textTransform: "capitalize"}} label={param.value}
                                 onDelete={() => removeFilterParam(param)}
                                 icon={<Square style={{color: param.value}}/>}/>
                }[param.type]
              })
            }

          </Stack> : null}
          {listImages}

          {
            images?.length > 0 ?
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Button
                  disabled={pagination >= filteredImages?.length}
                  onClick={() => setPagination(pagination + 10 > filteredImages?.length ? filteredImages?.length : pagination + 10)}
                  sx={{ textAlign: "center", textTransform: "capitalize" }}
                  style={{margin: "10px 0px 60px 0px"}}
                >
                  <p style={{fontSize: "1.2em", padding: "0px 16px"}}>Load More</p>
                </Button>
              </Box>
              :
              <Stack direction={"column"} gap={1} alignItems={"center"} justifyContent={"center"} sx={{width: "100%", height: '100%'}}>
                <CircularProgress />
                <Typography>Loading...</Typography>
              </Stack>
          }
        </Box>
      </Stack>

    </MainLayout>
  )
}