import React, {useContext, useEffect, useRef, useState} from "react";
import {Box, Button, Checkbox, Divider, Fade, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {deepPurple, grey} from "@mui/material/colors";
import GoogleButton from "react-google-button";
import {ArrowForwardIos} from "@mui/icons-material";
import {auth, db, googleProvider} from "../../../config/firebase";
import {signInWithEmailAndPassword, signInWithPopup, signOut} from "firebase/auth";
import {toast} from "react-toastify";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {Context as AppContext} from "../../../context/AppContext";
import {Page} from "../../../context/SignInContext";
import {freeSubscription} from "../../../utils/free-subscription";
import {USER_SUBSCRIPTION_COLLECTION} from "../../../db/collections";

export const LoginPage = ({setPage, handleClose}) => {
  const {createCollection} = useContext(AppContext);

  const [loginWithMail, setLoginWithMail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const passwordRef = useRef(null);

  useEffect(() => {
    if (loginWithMail) {
      passwordRef.current?.focus();
    }
  }, [loginWithMail])

  async function handleLoginGoogle() {
    try {
      await signInWithPopup(auth, googleProvider);
      if (!auth.currentUser) {
        return;
      }
      const id = auth.currentUser.uid;
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        const subscription = {
          ...freeSubscription,
          userUid: auth.currentUser.uid,
          createdAt: new Date().getTime(),
          product: {
            ...freeSubscription.product,
            timestamp: new Date().getTime()
          }
        }
        const userRef = doc(db, "users", auth.currentUser.uid);

        await setDoc(userRef,
          {
            uid: auth?.currentUser?.uid,
            displayName: auth.currentUser?.displayName,
            notifications: false,
            newsletter: false,
            email: email,
            authUid: auth?.currentUser?.uid,
            photoURL: auth?.currentUser?.photoURL,
            cart: [],
            subscription: subscription
          });
        const subscriptionRef = doc(db, USER_SUBSCRIPTION_COLLECTION, auth.currentUser.uid);
        await setDoc(subscriptionRef, {...subscription});

        await createCollection("Favorites", "", null, [])
      }
      handleClose();
    } catch (err) {
      if (auth.currentUser) {
        await signOut(auth);
      }
      console.log(err);
    }
  }

  const loginHandle = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      handleClose()
    } catch (err) {
      toast.error(err.message);
    }
  }

  const onSubmit = e => {
    e.preventDefault();
    if (!loginWithMail) {
      setLoginWithMail(true);
      return;
    }
    loginHandle();
  }

  return (
    <Stack direction={"column"} gap={1} sx={{flex: 1}} justifyContent={'center'}
           alignItems={"center"}>
      {/*HEADER*/}
      <Stack direction={"column"} gap={1} sx={{width: '100%'}}>
        {/*TITLE*/}
        <Typography sx={{color: grey[900]}} variant={'h2'} textAlign={"center"}
                    fontWeight={"bold"}>Login</Typography>
        {/*SIGN UP*/}
        <Box sx={{textAlign: "center"}}>
          <Typography sx={{color: grey[700]}} component={"span"}>Dont have account
            yet?</Typography>
          <Typography variant={"span"} onClick={() => setPage(Page.REGISTER)} sx={{
            pl: 1,
            color: deepPurple[500],
            fontWeight: 'bold',
            textDecoration: "none",
            cursor: "pointer",
            fontFamily: "Inter"
          }}>Sign up</Typography>
        </Box>
      </Stack>
      {/*LOGIN WITH MAIL*/}
      <Stack component={"form"} onSubmit={onSubmit} direction={"column"} gap={1} sx={{width: '100%'}}>
        <TextField
          type={"email"}
          variant={"standard"}
          required
          fullWidth
          label={"Email"}
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder={"Enter your email address"}
        />
        {
          loginWithMail &&
          <Fade
            in={loginWithMail}
          >
            <TextField
              ref={passwordRef}
              autoFocus
              type={"password"}
              variant={"standard"}
              required
              fullWidth
              label={"Password"}
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder={"Password"}
            />
          </Fade>
        }

        <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"}
               useFlexGap flexWrap={"wrap"}>
          <FormControlLabel sx={{color: grey[700]}} control={<Checkbox defaultChecked
                                                                       sx={{"&.Mui-checked": {color: deepPurple[700]}}}/>}
                            label="Remember me"/>
          <Button sx={{textDecoration: "none", textTransform: "none", color: grey[700]}} onClick={() => setPage(Page.FORGOT_PASSWORD)}>Forgot password?</Button>
        </Stack>
        {
          loginWithMail ?
            <Button
              fullWidth
              type={"submit"}
              variant={"contained"}
              sx={{
                textTransform: "capitalize",
                bgcolor: deepPurple[400],
                fontWeight: "bold",
                '&:hover': {bgcolor: deepPurple[600]}
              }}
            >
              Log in <ArrowForwardIos sx={{fontSize: 12}}/>
            </Button>
            :
            <Button
              fullWidth
              type={"submit"}
              variant={"contained"}
              disabled={!email}
              sx={{
                textTransform: "capitalize",
                bgcolor: deepPurple[400],
                fontWeight: "bold",
                marginTop: "20px",
                '&:hover': {bgcolor: deepPurple[600]}
              }}
            >
              Continue <ArrowForwardIos sx={{fontSize: 12}}/>
            </Button>
        }

      </Stack>
      {
        !loginWithMail &&
        <Fade
          in={!loginWithMail}
        >
          <Box sx={{width: "100%"}}>
            {!loginWithMail && <Divider sx={{color: grey[500], fontFamily: "Inter", fontSize: "0.9em", padding: "7px 4px" }}>or sign in with</Divider>}
          </Box>
        </Fade>
      }
      {
        !loginWithMail &&
        <Fade
          in={!loginWithMail}
        >
          <Box sx={{width: "100%"}}>

            <GoogleButton
              style={{width: "100%", borderRadius: "8px"}}
              onClick={() => handleLoginGoogle()}
              type={"light"}
            />
          </Box>
        </Fade>
      }

    </Stack>
  )
}
