import {collection, getDocs} from "firebase/firestore";
import {db} from "../config/firebase";

export const PlansRepository = {
  getAll: async () => {
    const querySnapshot = await getDocs(collection(db, "plans"));
    const items = [];
    querySnapshot.forEach(doc => {
      const col = {
        ...doc?.data()
      };
      items.push(col);
    });
    return items;
  }
}