import React, {useContext} from "react";
import {
    Button,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    IconCreditCard, IconCurrencyDollar,
    IconDownload,
    IconHeart, IconLogin, IconLogout, IconMedal,
    IconMenu, IconPhotoPlus,
    IconPhotoSearch,
    IconShoppingCartPlus, IconTrophy,
    IconUser, IconX
} from "@tabler/icons-react";
import {deepPurple} from "@mui/material/colors";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {signOut} from "firebase/auth";
import {auth} from "../../../config/firebase";
import {SignInContext} from "../../../context/SignInContext";
import logo from "../../../assets/img/logo.png";
import logoWithText from "../../../assets/img/logo-with-text.png";

export const HeaderDrawer = ({open, handleClose, openCart}) => {
    const {openModal} = useContext(SignInContext);
    const navigate = useNavigate()

    const logoutHandle = async () => {
        try {
            await signOut(auth);
            navigate('/')
            handleClose();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <Drawer
            anchor={"right"}
            open={open}
            onClose={handleClose}
            sx={{
                ml: 2,
                height: "100vh",
                width: '100%',
                flex: 1,
                display: {xs: "inherit", md: "none"}
            }}
            PaperProps={{
                sx: {
                    maxWidth: "400px",
                    width: "90% !important",
                },
            }}
        >
            <Stack direction={"column"} sx={{
                height: "100vh",
                // maxWidth: "300px",
                width: "100% !important",
                bgcolor: "#FFF"
            }}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ p: 2, pb: 0, bgcolor: "#FFF" }}>
                    <Button
                      component={Link}
                      to={"/"}
                      variant={"text"}
                      sx={{color: "#000"}}
                    >
                        <img
                          src={logoWithText}
                          alt={"Logo"}
                          width={70}
                        />
                        {/*<Typography sx={{ml: 1, fontSize: "1.375rem", fontFamily: "Sonar Sans", fontWeight: 500}} textTransform={"capitalize"} fontWeight={"bold"}>Sura صورة</Typography>*/}
                    </Button>
                    <IconButton onClick={handleClose}>
                        <IconX />
                    </IconButton>
                </Stack>
                <Box sx={{ flex: 1, maxHeight: "100%", overflowY: 'auto', marginTop: "15px" }}>
                    <List>
                        <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/images"}>
                            <ListItemIcon>
                                <IconPhotoSearch />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"subtitle2"} fontWeight={"bold"}>Browse</Typography>
                            </ListItemText>
                        </ListItemButton>

                        <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/pricing"}>
                            <ListItemIcon>
                                <IconCurrencyDollar />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"subtitle2"} fontWeight={"bold"}>Pricing</Typography>
                            </ListItemText>
                        </ListItemButton>

                        <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/mission"}>
                            <ListItemIcon>
                                <IconMedal />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"subtitle2"} fontWeight={"bold"}>Mission</Typography>
                            </ListItemText>
                        </ListItemButton>

                        <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/enterprise"}>
                            <ListItemIcon>
                                <IconTrophy />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"subtitle2"} fontWeight={"bold"}>Enterprise</Typography>
                            </ListItemText>
                        </ListItemButton>

                        <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/request-image"}>
                            <ListItemIcon>
                                <IconPhotoPlus />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"subtitle2"} fontWeight={"bold"}>Request Image</Typography>
                            </ListItemText>
                        </ListItemButton>

                        {
                            auth?.currentUser ?
                                <>
                                    <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/collections"}>
                                        <ListItemIcon>
                                            <IconHeart />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={"subtitle2"} fontWeight={"bold"}>Collections</Typography>
                                        </ListItemText>
                                    </ListItemButton>

                                    <ListItemButton onClick={openCart}>
                                        <ListItemIcon>
                                            <IconShoppingCartPlus />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={"subtitle2"} fontWeight={"bold"}>Cart</Typography>
                                        </ListItemText>
                                    </ListItemButton>

                                    <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/profile"}>
                                        <ListItemIcon>
                                            <IconUser />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={"subtitle2"} fontWeight={"bold"}>Edit profile</Typography>
                                        </ListItemText>
                                    </ListItemButton>

                                    <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/downloads"}>
                                        <ListItemIcon>
                                            <IconDownload />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={"subtitle2"} fontWeight={"bold"}>Downloads</Typography>
                                        </ListItemText>
                                    </ListItemButton>

                                    <ListItemButton onClick={handleClose} component={NavLink} className={"nav-link"} to={"/subscriptions"}>
                                        <ListItemIcon>
                                            <IconCreditCard />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={"subtitle2"} fontWeight={"bold"}>My subscription</Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                <ListItemButton onClick={logoutHandle} className={"nav-link"} >
                                    <ListItemIcon >
                                        <IconLogout />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant={"subtitle2"} fontWeight={"bold"}>Logout</Typography>
                                    </ListItemText>
                                </ListItemButton>
                                </>
                                :
                                <ListItemButton onClick={() => openModal()} className={"nav-link"}>
                                    <ListItemIcon >
                                        <IconLogin />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant={"subtitle2"} fontWeight={"bold"}>Sign in</Typography>
                                    </ListItemText>
                                </ListItemButton>
                        }



                    </List>
                </Box>
            </Stack>
        </Drawer>
    )
}
