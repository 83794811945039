import createContext from "./createContext";
import {fetchImages} from "../utils/APIs";
import {doc, getDocs, query, setDoc, updateDoc, where, or, getDoc, deleteDoc, writeBatch} from "firebase/firestore";
import {
    USER_COLLECTION,
    USER_COLLECTIONS_COLLECTION, USER_IMAGES_COLLECTION, USER_SUBSCRIPTION_COLLECTION,
    userCollectionsCollection, userImagesCollection,
} from "../db/collections";
import {auth, db} from "../config/firebase";
import {toast} from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

const appInitialState = {
    images: [],
    keywords: [],
    ownedImages: [],
    cart: [],
    collections: [],
    openCart: false,
    userDetails: {
        uid: "",
        displayName: "",
        notifications: false,
        newsletter: false,
        email: "",
        authUid: "",
        photoURL: "",
        subscription: null,
        cart: [],
    }
}

const appReducer = (state, action) => {
    switch (action.type) {
        case 'update_collections':
            return {...state, collections: action.payload}
        case 'update_cart':
            return {...state, cart: action.payload}
        case 'update_owned':
            return {...state, ownedImages: action.payload}
        case 'load_images':
            return {...state, images: action.payload}
        case 'load_keywords':
            return {...state, keywords: action.payload}
        case 'update_user':
            return {...state, userDetails: action.payload}
        default:
            return state;
    }
}

// these functions-old will act as repositories, here we will make calls to the database and be able to reuse them in different components
const createCollection = (dispatch) => {
    return async (name, description, images, members) => {
        try{
            const uuid = uuidv4();
            if (!members || Object.values(members)?.length === 0) {
                members = {
                    [auth.currentUser.uid]: {
                        displayName: auth?.currentUser?.displayName,
                        email: auth?.currentUser?.email,
                        photoUrl: auth?.currentUser?.photoURL,
                        role: "editor"
                    }
                };
            }

            const data = {
                uid: uuid,
                name: name,
                description: description,
                userUid: auth?.currentUser?.uid,
                images: images ? [images].flat() : [],
                members: members
            };
            await setDoc(doc(db, USER_COLLECTIONS_COLLECTION, uuid), data);

            await getUserCollections(dispatch)();
            toast.success("Successfully created collection.")
        } catch (err) {
            toast.error(err);
            console.log(err);
        }
    }
}

const updateCollection = (dispatch) => {
    return async (id, data) => {
        if (!data) {
            return;
        }
        try{
            await setDoc(doc(db, USER_COLLECTIONS_COLLECTION, id), data);
            await getUserCollections(dispatch)();
            toast.success("Successfully updated collection.")
        } catch (err) {
            toast.error(err?.message);
            console.error(err);
        }
    }
}

const removeCollection = (dispatch) => {
    return async (id) => {
        try{
            await deleteDoc(doc(db, USER_COLLECTIONS_COLLECTION, id));
            await getUserCollections(dispatch)();
            toast.success("Successfully deleted collection.")
        } catch (err) {
            toast.error(err?.message);
            console.error(err);
        }
    }
}

const getUserCollections = (dispatch) => {
    return async () => {
        try {
            let items = [];
            if (auth?.currentUser?.uid) {
                const authUid = auth?.currentUser?.uid
                const q = query(userCollectionsCollection, or(
                  where("userUid", "==", authUid),
                  where(`members.${authUid}.uid`, "==", authUid),
                ));
                const querySnapshot = await getDocs(q);
                // items = querySnapshot.data();
                querySnapshot.forEach((doc) => {
                    const col = {
                        ...doc?.data()
                    };
                    items.push(col);
                })
            }
            dispatch({type: "update_collections", payload: items});
        } catch (err) {
            console.log(err);
            toast.error(err.message);
        }
    }
}

const getUserOwnedImages = (dispatch) => {
    return async () => {
        try {
            let items = [];
            if (auth?.currentUser?.uid) {
                const q = query(userImagesCollection, where("userUid", "==", auth?.currentUser?.uid));
                const querySnapshot = await getDocs(q);
                // items = querySnapshot.data();
                querySnapshot.forEach((doc) => {
                    const col = {
                        uid: doc.id,
                        ...doc?.data()
                    };
                    items.push(col);
                })
            }
            dispatch({type: "update_owned", payload: items});
        } catch (err) {
            console.log(err);
            toast.error(err.message);
        }
    }
}

const buyImages = (dispatch) => {
    return async (items, userDetails) => {
        try{
            const numOfCredits = userDetails?.subscription?.numberOfCredits || 0;
            if (numOfCredits < items.length) {
                toast.error("You dont have enough credits, please buy more!");
                return;
            }
            const batch = writeBatch(db);

            for (const item of items) {
                const uuid = uuidv4();
                item.txnUid = uuid;
                item.userUid = auth?.currentUser?.uid;
                const docRef = doc(db, USER_IMAGES_COLLECTION, uuid);
                batch.set(docRef, item);
                // await setDoc(doc(db, USER_IMAGES_COLLECTION, uuid), item);
            }
            const subscriptionRef = doc(db, USER_SUBSCRIPTION_COLLECTION, auth.currentUser.uid);
            batch.update(subscriptionRef, {
                numberOfCredits: numOfCredits - items.length
            })
            // await updateDoc(subscriptionRef, {
            //     numberOfCredits: numOfCredits - items.length
            // });
            // Update user cart
            const docRef = doc(db, 'users', auth.currentUser.uid);
            batch.update(docRef, {cart: []});
            // await updateDoc(docRef, {cart: []});
            // Run transaction
            await batch.commit();

            await getUserOwnedImages(dispatch)();
            await getUserDetails(dispatch)();
        } catch (err) {
            toast.error(err);
            console.log(err);
        }
    }
}

const getUserDetails = (dispatch) => {
    return async () => {
        if (auth?.currentUser?.uid) {
            try{
                const docRef = doc(db, USER_COLLECTION, auth?.currentUser?.uid);
                const docSnap = await getDoc(docRef);
                // items = querySnapshot.data();
                if (docSnap?.exists()) {
                    const userDetails = {...docSnap?.data()}

                    const docSubscriptionRef = doc(db, USER_SUBSCRIPTION_COLLECTION, auth?.currentUser?.uid);
                    const docSubscriptionSnap = await getDoc(docSubscriptionRef);
                    userDetails.subscription = docSubscriptionSnap.exists() ? {...docSubscriptionSnap?.data()} : null;
                    dispatch({type: "update_user", payload: userDetails});
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        }
    }
}

const updateUserDetails = (dispatch) => {
    return async (data) => {
        if (data) {
            try {
                const docRef = doc(db, 'users', auth.currentUser.uid);
                await updateDoc(docRef, {...data});
                dispatch({type: "update_user", payload: {...data}});
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        }
    }
}

const updateSubscription = (dispatch) => {
    return async (data) => {
        if (data) {
            try {
                const docRef = doc(db, USER_SUBSCRIPTION_COLLECTION, auth.currentUser.uid);
                await setDoc(docRef, {...data});
                await getUserDetails(dispatch)();
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        }
    }
}

const loadImages = (dispatch) => {
    return async () => {
        const images = await fetchImages();
        const keywords = Array.from(new Set(images?.map(item => item?.tags).flat()?.map(item => item?.toLowerCase())));
        dispatch({type: "load_images", payload: images})
        dispatch({type: "load_keywords", payload: [...keywords]})
    }
}

export const {Provider, Context} = createContext(
    appReducer,
    {
        getUserCollections,
        updateCollection,
        removeCollection,
        getUserDetails,
        updateUserDetails,
        createCollection,
        getUserOwnedImages,
        buyImages,
        loadImages,
        updateSubscription,
    },
    {...appInitialState}
)
