import React, {useState} from "react";
import {MainLayout} from "../../layouts/main-layout/MainLayout";

import EnterpriseSection from "./../../plasmicComponents/EnterpriseSection";

const Enterprise = () => {
  return (
    <MainLayout>
      {/*Submission form is handled in the ./plasmicComponents/EnterpriseSection.jsx file*/}
      <EnterpriseSection/>
    </MainLayout>
  )
}

export default Enterprise;