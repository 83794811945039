import React, {useContext, useMemo, useState} from "react";
import {
  Box,
  Button,
  Divider, List, ListItem, ListItemText, Menu,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {deepPurple, green, grey, orange, yellow} from "@mui/material/colors";
import {ArrowForwardIos, Circle} from "@mui/icons-material";
import {auth, db, googleProvider} from "../../../config/firebase";
import {createUserWithEmailAndPassword, getAuth, signInWithPopup, signOut} from "firebase/auth"
import {toast} from "react-toastify";
import {query, where, getDocs, doc, setDoc, getDoc} from "firebase/firestore";
import {USER_SUBSCRIPTION_COLLECTION, usersCollection} from "../../../db/collections";
import {Context as AppContext} from "../../../context/AppContext";
import GoogleButton from "react-google-button";
import {Page} from "../../../context/SignInContext";
import {freeSubscription} from "../../../utils/free-subscription";

export const RegisterPage = ({setPage, handleClose}) => {
  const {createCollection} = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  async function handleLoginGoogle() {
    try {
      await signInWithPopup(auth, googleProvider);
      if (!auth.currentUser) {
        return;
      }
      const id = auth?.currentUser?.uid;
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        const subscription = {
          ...freeSubscription,
          userUid: id,
          createdAt: new Date().getTime(),
          product: {
            ...freeSubscription.product,
            timestamp: new Date().getTime(),
            userUid: auth?.currentUser?.uid
          }
        }
        const userRef = doc(db, "users", id);

        await setDoc(userRef,
          {
            uid: id,
            displayName: auth.currentUser?.displayName,
            notifications: false,
            newsletter: false,
            email: email,
            authUid: id,
            photoURL: auth?.currentUser?.photoURL,
            cart: [],
            subscription: subscription
          });
        const subscriptionRef = doc(db, USER_SUBSCRIPTION_COLLECTION, auth.currentUser.uid);
        await setDoc(subscriptionRef, {...subscription});

        await createCollection("Favorites", "", null, [])
      }
      handleClose();
    } catch (err) {
      if (auth.currentUser) {
        await signOut(auth);
      }
      console.log(err);
    }
  }

  // Function to check if an email is already in use
  async function isEmailTaken(email) {
    const q = query(usersCollection, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot?.empty;
  }

  const signUp = async () => {
    if (!email || !confirmEmail || !password || !confirmPassword) {
      toast.error("All fields are required!");
      return;
    }
    const emailTaken = await isEmailTaken(email);

    if (emailTaken) {
      toast.error("Email is already taken.");
      return;
    }

    if (email !== confirmEmail) {
      toast.error("Emails do not match");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password do not match");
      return;
    }
    try {

      await createUserWithEmailAndPassword(auth, email, password);
      const subscription = {
        ...freeSubscription,
        userUid: auth.currentUser.uid,
        createdAt: new Date().getTime(),
        product: {
          ...freeSubscription.product,
          timestamp: new Date().getTime(),
          userUid: auth?.currentUser?.uid
        }
      }
      const userRef = doc(db, "users", auth.currentUser.uid);

      await setDoc(userRef,
        {
          uid: auth?.currentUser?.uid,
          displayName: auth.currentUser?.displayName,
          notifications: false,
          newsletter: false,
          email: email,
          authUid: auth?.currentUser?.uid,
          photoURL: auth?.currentUser?.photoURL,
          cart: [],
          subscription: subscription
        });
      const subscriptionRef = doc(db, USER_SUBSCRIPTION_COLLECTION, auth.currentUser.uid);
      await setDoc(subscriptionRef, {...subscription});

      await createCollection("Favorites", "", null, [])
      handleClose();
    } catch (err) {
      if (auth.currentUser) {
        await auth.currentUser.delete();
        await signOut(auth);
      }
      toast.error(err.message);
      console.log(err);
    }
  }

  return (
    <Stack direction={"column"} gap={1} sx={{flex: 1}}
           justifyContent={'center'}
           alignItems={"center"}>
      {/*TITLE*/}
      <Typography sx={{color: grey[900]}} variant={'h2'} textAlign={"center"}
                  fontWeight={"bold"}>Sign up</Typography>
      {/*SIGN UP*/}
      <Box sx={{textAlign: "center"}}>
        <Typography sx={{color: grey[700]}} component={"span"}>Already have an account?</Typography>
        <Typography variant={"span"} onClick={() => setPage(Page.LOGIN)} sx={{
          pl: 1,
          color: deepPurple[500],
          fontWeight: 'bold',
          textDecoration: "none",
          cursor: "pointer",
          fontFamily: "Inter"
        }}>Sign in</Typography>
      </Box>

      {/*LOGIN WITH MAIL*/}
      <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
        <TextField
          type={"email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant={"standard"}
          required
          fullWidth
          label={"Email"}
          placeholder={"Enter your email address"}
        />
        <TextField
          type={"email"}
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
          variant={"standard"}
          required
          fullWidth
          label={"Type your email again"}
          placeholder={"Enter your email address"}
        />
        <PasswordInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          type={"password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          variant={"standard"}
          required
          fullWidth
          label={"Type your password again"}
          placeholder={"Password"}
        />

        <Button
          fullWidth
          variant={"contained"}
          onClick={signUp}
          sx={{
            textTransform: "capitalize",
            bgcolor: deepPurple[400],
            fontWeight: "bold",
            marginTop: "20px",
            '&:hover': {bgcolor: deepPurple[600]}
          }}
        >
          Sign up <ArrowForwardIos sx={{fontSize: 12}}/>
        </Button>

        <Box sx={{width: "100%"}}>
          <Divider sx={{color: grey[500], fontFamily: "Inter", fontSize: "0.9em", padding: "7px 4px"}}>or sign up with</Divider>
        </Box>
        <GoogleButton
          style={{borderRadius: "8px", width: '100%'}}
          onClick={() => handleLoginGoogle()}
          type={"light"}
        />
      </Stack>

    </Stack>
  )
}


export default function PasswordInput({value, onChange}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleChange = (event) => {
    setAnchorEl(event.currentTarget);
    onChange(event);
  };
  const handleBlur = () => {
    setAnchorEl(null);
  };

  const getPasswordStrength = () => {
    let counter = 0;
    let bgcolor = grey[300];

    if (value?.length > 0) {
      bgcolor = yellow[500];
      counter += 1;
      if (value?.length > 5) {
        counter += 1;
        if (/(?=.*[a-z])/.test(value) && /(?=.*[A-Z])/.test(value)) {
          counter += 1;
        }
        if (/(?=.*[-+_!@#$%^&*.,?])/.test(value)) {
          counter += 1;
        }
        if (/(?=.*[\d])/.test(value)) {
          counter += 1;
        }
      }
    }

    if (counter === 1) {
      bgcolor = yellow[500]
    } else if (counter === 2) {
      bgcolor = orange[300]
    } else if (counter === 3) {
      bgcolor = orange[600]
    } else if (counter === 4) {
      bgcolor = green[400]
    } else if (counter === 5) {
      bgcolor = green[600]
    }

    return {bgcolor, counter}
  }

  const {bgcolor, counter} = getPasswordStrength();


  return (
    <div>
      <TextField
        type={"password"}
        value={value}
        onChange={handleChange}
        // onFocus={handleChange}
        onBlur={handleBlur}
        variant={"standard"}
        required
        fullWidth
        label={"Password"}
        placeholder={"Password"}
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleBlur}
        disableAutoFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{sx: {p: 2}}}
      >
        <Box>
          <Typography fontSize={13}>Must have at least 6 characters.</Typography>
          <Stack direction={"row"} sx={{width: "100%", height: 3, mt: 1}} gap={1}>
            <Divider color={bgcolor} sx={{flex: 1}}/>
            <Divider color={counter >= 2 ? bgcolor : grey[300]} sx={{flex: 1}}/>
            <Divider color={counter >= 3 ? bgcolor : grey[300]} sx={{flex: 1}}/>
            <Divider color={counter >= 4 ? bgcolor : grey[300]} sx={{flex: 1}}/>
          </Stack>
          <Typography sx={{mt: 2}} fontSize={13}>It's better to have:</Typography>
          <List>
            <ListItem sx={{my: 0, py: 0}}>
              <ListItemText
                sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                secondary={<>
                  <Circle sx={{color: deepPurple[500], fontSize: 10, mr: 1}}/>
                  Upper & lower case letters
                </>}
              />
            </ListItem>
            <ListItem sx={{my: 0, py: 0}}>

              <ListItemText
                sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                secondary={<>
                  <Circle sx={{color: deepPurple[500], fontSize: 10, mr: 1}}/>
                  A symbol (#$&)
                </>}
              />
            </ListItem>
            <ListItem sx={{my: 0, py: 0}}>

              <ListItemText
                sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                secondary={<>
                  <Circle sx={{color: deepPurple[500], fontSize: 10, mr: 1}}/>
                  A longer password
                </>}
              />
            </ListItem>

          </List>
        </Box>

      </Menu>
    </div>
  );
}