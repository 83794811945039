import React, {useState} from "react";
import Skeleton from '@mui/material/Skeleton';

export const Image = ({url, name, width = '100%', height = '250px', style, ...props}) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            {
                !loaded &&
                <Skeleton variant="rectangular" animation="wave" width={width} height={height} />
            }

            <img src={`${url}?w=100%&fit=crop&auto=format`}
                 srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                 alt={name || url}
                 onLoad={(e) => setLoaded(true)}
                 width={width || "100%"}
                 height={height || "250px"}
                 loading={"lazy"}
                 style={{...style, objectFit: "cover", opacity: loaded ? 1 : 0}}
                 {...props}
            />
        </>
    )
}