import ReusableModal from "./ReusableModal";
import React from "react";
import {useAtom} from "jotai";
import {ContactFormAtom} from "../../atoms";
import {ContactForm} from "../forms/ContactForm";

export const ContactFormModal = () => {
  const [contactFormState, setContactFormState] = useAtom(ContactFormAtom);

  const {open, message, title} = contactFormState;

  const handleClose = () => {
    setContactFormState({
      open: false,
      message: "",
      title: ""
    })
  }

  return (
    <ReusableModal open={open} handleClose={handleClose} >
      <ContactForm message={message || ""} title={title || "Feel free to contact us"} />
    </ReusableModal>
  )
}